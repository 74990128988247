

import { defineComponent, onMounted, ref } from "vue";
import EventMap from "@/components/map/EventMap.vue";

export default defineComponent( {
    name: 'HeaderMap',
    components: { EventMap },
    props: {},
    setup() {
        const firstShow = ref( false );

        onMounted( () => {
            const myModalEl = document.getElementById( 'mapModal' );
            myModalEl.addEventListener( 'shown.bs.modal', function( event ) {
                firstShow.value = true;
            } );

        } );

        return {
            firstShow
        };
    }
} );
