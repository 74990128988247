

import { defineComponent, getCurrentInstance, onMounted, Ref, ref, watch } from "vue";
import Home from "@/components/home/Home.vue";
import LocationModal from "@/components/location/LocationModal.vue";
import EventDetailModal from "@/components/event/EventDetailModal.vue";
import ArtistModal from "@/components/artist/ArtistModal.vue";
import { ArtistApi, ArtistDto, EventApi, EventDto, LocationApi, LocationDto } from "@/api";
import { ApiService } from "@/services/ApiService";
import { useStore } from "vuex";
import { lastSearchQuery, setTitle } from "@/utils/Utils";

export default defineComponent( {
    name: 'BodyContainer',
    components: { ArtistModal, EventDetailModal, LocationModal, Home },
    props: {},
    setup() {
        const currEvent: Ref<EventDto> = ref( null );
        const currLocation: Ref<LocationDto> = ref( null );
        const currArtist: Ref<ArtistDto> = ref( null );
        const locApi = ApiService.wrap( LocationApi );
        const artApi = ApiService.wrap( ArtistApi );
        const eventApi = ApiService.wrap( EventApi );
        const app = getCurrentInstance();

        onMounted( () => {
            openModal();

            app.appContext.config.globalProperties.emitter.on( "closeModal", () => {
                setTimeout( () => checkIfAllModalsClosed(), 500 );
            } );

        } );

        function checkIfAllModalsClosed() {
            if( document.getElementsByClassName( "modal show" ).length === 0 ) {
                window.history.pushState( {}, "", lastSearchQuery );
                setTitle( "" );
            }
        }

        function openModal() {
            const parts = window.location.pathname.split( '/' ).filter( n => n );

            if( parts.length >= 2 ) {
                switch( parts[0] ) {
                    case 'event':
                        openEventModal( parts[1] );
                        break;
                    case 'location':
                        openLocationModal( parts[1] );
                        break;
                    case 'artist':
                        openArtistModal( parts[1] );
                        break;
                }
            }
        }

        function openEventModal( id ) {
            eventApi.getEventById( id ).then( ( r ) => {
                currEvent.value = r.data;
                document.getElementById( "urlEventDetailModalBtn" ).click();
            } ).catch( ApiService.defaultErrorHandler );
        }

        function openLocationModal( id ) {
            locApi.getLocationById( id ).then( ( r ) => {
                currLocation.value = r.data;
                document.getElementById( "urlLocationModalBtn" ).click();
            } ).catch( ApiService.defaultErrorHandler );
        }

        function openArtistModal( id ) {
            artApi.getArtistById( id ).then( ( r ) => {
                currArtist.value = r.data;
                document.getElementById( "urlArtistModalBtn" ).click();
            } ).catch( ApiService.defaultErrorHandler );
        }

        return {
            currEvent,
            currLocation,
            currArtist
        };
    }
} );
