import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = ["data-bs-target", "onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Gallery = _resolveComponent("Gallery")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t( 'common.gallery' )), 1),
    _createVNode(_component_Gallery, {
      "single-line": true,
      id: _ctx.id + 'Gallery',
      "no-grid": true,
      class: "mt-2"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleryImages, (image, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "d-inline-block me-2 cursor-pointer"
          }, [
            _createElementVNode("button", {
              "data-bs-toggle": "modal",
              class: "clean-btn",
              "data-bs-target": '#' + _ctx.id,
              onClick: ($event: any) => (_ctx.setCurrImage(image))
            }, [
              _createElementVNode("img", {
                src: image,
                class: "img-thumbnail img-cover"
              }, null, 8, _hoisted_3)
            ], 8, _hoisted_2)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["id"])
  ], 64))
}