

import { defineComponent, onMounted, PropType, Ref, ref } from "vue";
import { EventDto, LocationHeaderDto } from "@/api";
import {
    getNextDates,
    periodHasNoRepeats,
    PeriodStartToDate,
    periodsToString,
    periodsToStringAndNextDate
} from "@/utils/PeriodUtils";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { getPerformerTitle } from "@/utils/EventUtils";

interface Props {
    event: EventDto,
    since: Date
}

export default defineComponent( {
    name: 'EventCard',
    components: {},
    props: {
        event: {
            type: Object as PropType<EventDto>
        },
        since: {
            type: Object as PropType<Date>,
            default: () => new Date()
        }
    },
    setup( props: Props ) {
        const i18n = useI18n();
        const earliestLocation: Ref<LocationHeaderDto> = ref( null );
        const earliestDateString: Ref<string> = ref( "" );
        const dateSpan: Ref<string> = ref( "" );
        const MAX_Artist_Length = 45;


        onMounted( () => {
            let earliest = null;
            const locationsInRange = props.event.locations.filter( ( l ) => l.inRange );
            for( const location of locationsInRange ) {
                const currDate = periodsToStringAndNextDate( props.event.periods, i18n, props.since );
                if( currDate && ( !earliest || earliest > PeriodStartToDate( currDate[1] ) ) ) {
                    earliest = currDate[1];
                    earliestLocation.value = location;
                }
            }
            const locationInRangeIds = locationsInRange.map( l => l.id );
            const periodsInRange = props.event.periods.filter( p => locationInRangeIds.some( id => p.locationIds.includes( id ) ) );
            const nextDate = periodsToStringAndNextDate( periodsInRange, i18n, props.since );
            earliestDateString.value = nextDate[0];

            earliest = null;
            for( const period of periodsInRange.filter( p => !periodHasNoRepeats( p ) ) ) {
                let start = PeriodStartToDate( period );
                if( start && ( !earliest || earliest > start ) ) {
                    earliest = start;
                }
            }

            if( earliest && earliest > props.since ) {
                dateSpan.value = i18n.t( 'time.startsAt', { date: moment( earliest ).format( i18n.t( 'moment.format' ) ) } );
            }
        } );

        function getEventPerformerTitle(): string {
            return getPerformerTitle( [], props.event, i18n, MAX_Artist_Length );
        }

        return {
            earliestDateString,
            earliestLocation,
            dateSpan,
            getEventPerformerTitle
        };
    }
} );
