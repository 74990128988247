

import { defineComponent, ref, Ref } from "vue";
import HeaderSearch from "@/components/header/HeaderSearch.vue";
import HeaderUserSpace from "@/components/header/HeaderUserSpace.vue";
import HeaderLocationReset from "@/components/header/HeaderLocationReset.vue";
import HeaderMap from "@/components/header/HeaderMap.vue";

export default defineComponent( {
    name: 'HeaderContainer',
    components: { HeaderMap, HeaderUserSpace, HeaderSearch, HeaderLocationReset },
    props: {},
    setup() {
    }
} );
