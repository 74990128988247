

import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent( {
    name: 'HeaderLocationReset',
    components: {},
    props: {},
    setup() {
        const store = useStore();

        function resetGeoLocation() {
            store.commit( 'setSearchLocation', { lat: 9999, long: 9999 } );
        }

        return {
            resetGeoLocation
        };
    }
} );
