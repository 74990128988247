import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ms-2 px-2 my-auto d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "bi bi-geo cursor-pointer text-light",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetGeoLocation && _ctx.resetGeoLocation(...args)))
    })
  ]))
}