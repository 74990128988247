import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { ConfigService } from "@/services/ConfigService";

const getDefaultState = () => {
    return {
        eventSearchResult: null,
        searchDate: Date()
    };
};

const state = getDefaultState();

const mutations = {
    setEventSearchResult( state, results ) {
        state.eventSearchResult = results;
    },
    setSearchDate( state, date ) {
        state.searchDate = date;
    }
};

const store = createStore( {
    state,
    mutations,
    plugins: [ createPersistedState() ],
} );

export default store;