

import { defineComponent } from "vue";

export default defineComponent( {
    name: 'HeaderUserSpace',
    components: {},
    props: {},
    setup() {
    }
} );
