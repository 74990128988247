

import { defineComponent, onUpdated, PropType, ref, Ref } from "vue";
import Gallery from "@/components/general/Gallery.vue";
import { EventHeaderDto, LocationHeaderDto } from "@/api";

export default defineComponent( {
    name: 'ModalLocationGallery',
    components: { Gallery },
    props: {
        locations: {
            type: Array as PropType<LocationHeaderDto[]>,
            default: () => []
        },
        id: {
            type: String
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {

        function setCurrLocation( id: number ) {
            emit( 'change', id );
        }

        return {
            setCurrLocation
        };
    }
} );
