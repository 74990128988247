/* eslint @typescript-eslint/no-var-requires: "off" */
import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from "vue-i18n";
import messages from '@/i18n/messages';
import { ConfigService } from "@/services/ConfigService";
import store from "./store";
import EventDetailModal from "@/components/event/EventDetailModal.vue";
import ArtistModal from "@/components/artist/ArtistModal.vue";
import LocationModal from "@/components/location/LocationModal.vue";
import { testPeriods } from "../test/PeriodTest";
import mitt from 'mitt';

const app = createApp( App );

export const i18n = createI18n( {
    locale: ConfigService.getFallbackLang(), //TODO: get lang of user
    messages,
    fallbackLocale: ConfigService.getFallbackLang(),
    silentTranslationWarn: true,
    silentFallbackWarn: true
} );
app.use( i18n );
app.use( store );
app.component( "EventDetailModal", EventDetailModal );
app.component( "ArtistModal", ArtistModal );
app.component( "LocationModal", LocationModal );
app.provide( 'searchResult', null );
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.mount( '#app' );

window.addEventListener( "popstate", function( event ) {
    console.log( "back" );
    window.location.reload();
} );

//testPeriods( i18n.global );