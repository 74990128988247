/* tslint:disable */
/* eslint-disable */
/**
 * Soctio
 * Backend-API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArtistBodyDto
 */
export interface ArtistBodyDto {
    /**
     * 
     * @type {Array<EventHeaderDto>}
     * @memberof ArtistBodyDto
     */
    'events'?: Array<EventHeaderDto>;
    /**
     * 
     * @type {string}
     * @memberof ArtistBodyDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof ArtistBodyDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {string}
     * @memberof ArtistBodyDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistBodyDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ArtistDto
 */
export interface ArtistDto {
    /**
     * 
     * @type {string}
     * @memberof ArtistDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistDto
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistDto
     */
    'profession'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ArtistDto
     */
    'thumbImage'?: string;
    /**
     * 
     * @type {Array<EventHeaderDto>}
     * @memberof ArtistDto
     */
    'events'?: Array<EventHeaderDto>;
    /**
     * 
     * @type {string}
     * @memberof ArtistDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof ArtistDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {string}
     * @memberof ArtistDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ArtistHeaderDto
 */
export interface ArtistHeaderDto {
    /**
     * 
     * @type {string}
     * @memberof ArtistHeaderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistHeaderDto
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistHeaderDto
     */
    'profession'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ArtistHeaderDto
     */
    'thumbImage'?: string;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'slug'?: string;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof CategoryDto
     */
    'children'?: Array<CategoryDto>;
}
/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface CustomError
 */
export interface CustomError {
    /**
     * 
     * @type {string}
     * @memberof CustomError
     */
    'message'?: string;
    /**
     * message stub
     * @type {string}
     * @memberof CustomError
     */
    'humanMessage'?: string;
}
/**
 * 
 * @export
 * @interface DefaultCategoriesDto
 */
export interface DefaultCategoriesDto {
    /**
     * array of categoryIds
     * @type {Array<CategoryDto>}
     * @memberof DefaultCategoriesDto
     */
    'categories'?: Array<CategoryDto>;
    /**
     * 
     * @type {Array<VirtualCategoryDto>}
     * @memberof DefaultCategoriesDto
     */
    'virtuellCategories'?: Array<VirtualCategoryDto>;
}
/**
 * 
 * @export
 * @interface EventBodyDto
 */
export interface EventBodyDto {
    /**
     * 
     * @type {string}
     * @memberof EventBodyDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ArtistHeaderDto>}
     * @memberof EventBodyDto
     */
    'performers'?: Array<ArtistHeaderDto>;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof EventBodyDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {Array<LocationHeaderDto>}
     * @memberof EventBodyDto
     */
    'locations'?: Array<LocationHeaderDto>;
    /**
     * flat list of categories
     * @type {Array<CategoryDto>}
     * @memberof EventBodyDto
     */
    'categories'?: Array<CategoryDto>;
    /**
     * flat list of categories
     * @type {Array<VirtualCategoryDto>}
     * @memberof EventBodyDto
     */
    'virtualCategories'?: Array<VirtualCategoryDto>;
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof EventBodyDto
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {string}
     * @memberof EventBodyDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventBodyDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'isSponsored'?: boolean;
    /**
     * 
     * @type {Array<PeriodDto>}
     * @memberof EventDto
     */
    'periods'?: Array<PeriodDto>;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'thumbImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ArtistHeaderDto>}
     * @memberof EventDto
     */
    'performers'?: Array<ArtistHeaderDto>;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof EventDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {Array<LocationHeaderDto>}
     * @memberof EventDto
     */
    'locations'?: Array<LocationHeaderDto>;
    /**
     * flat list of categories
     * @type {Array<CategoryDto>}
     * @memberof EventDto
     */
    'categories'?: Array<CategoryDto>;
    /**
     * flat list of categories
     * @type {Array<VirtualCategoryDto>}
     * @memberof EventDto
     */
    'virtualCategories'?: Array<VirtualCategoryDto>;
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof EventDto
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EventGroupDto
 */
export interface EventGroupDto {
    /**
     * 
     * @type {EventGroupDtoCategory}
     * @memberof EventGroupDto
     */
    'category'?: EventGroupDtoCategory;
    /**
     * 
     * @type {Array<EventDto>}
     * @memberof EventGroupDto
     */
    'events'?: Array<EventDto>;
    /**
     * 
     * @type {PageResponseDto}
     * @memberof EventGroupDto
     */
    'page'?: PageResponseDto;
}
/**
 * @type EventGroupDtoCategory
 * @export
 */
export type EventGroupDtoCategory = CategoryDto | VirtualCategoryDto;

/**
 * 
 * @export
 * @interface EventHeaderDto
 */
export interface EventHeaderDto {
    /**
     * 
     * @type {string}
     * @memberof EventHeaderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHeaderDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventHeaderDto
     */
    'isSponsored'?: boolean;
    /**
     * 
     * @type {Array<PeriodDto>}
     * @memberof EventHeaderDto
     */
    'periods'?: Array<PeriodDto>;
    /**
     * 
     * @type {string}
     * @memberof EventHeaderDto
     */
    'thumbImage'?: string;
}
/**
 * 
 * @export
 * @interface EventSearchDto
 */
export interface EventSearchDto {
    /**
     * 
     * @type {Array<VirtualCategoryDto>}
     * @memberof EventSearchDto
     */
    'virtualCategories'?: Array<VirtualCategoryDto>;
    /**
     * Flat list of category ids
     * @type {Array<string>}
     * @memberof EventSearchDto
     */
    'categories'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EventSearchDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {GeoDto}
     * @memberof EventSearchDto
     */
    'geoPosition'?: GeoDto;
    /**
     * 
     * @type {string}
     * @memberof EventSearchDto
     */
    'since'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSearchDto
     */
    'till'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSearchDto
     */
    'radius'?: number;
    /**
     * array of performer ids
     * @type {Array<string>}
     * @memberof EventSearchDto
     */
    'performers'?: Array<string>;
    /**
     * array of location ids
     * @type {Array<string>}
     * @memberof EventSearchDto
     */
    'locations'?: Array<string>;
    /**
     * 
     * @type {PageDto}
     * @memberof EventSearchDto
     */
    'page': PageDto;
}
/**
 * 
 * @export
 * @interface EventSearchResponseDto
 */
export interface EventSearchResponseDto {
    /**
     * 
     * @type {Array<EventGroupDto>}
     * @memberof EventSearchResponseDto
     */
    'eventGroups'?: Array<EventGroupDto>;
}
/**
 * 
 * @export
 * @interface GeoDto
 */
export interface GeoDto {
    /**
     * 
     * @type {number}
     * @memberof GeoDto
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeoDto
     */
    'long'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeoDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoDto
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoDto
     */
    'federalState'?: string;
    /**
     * Country as ISO 3166-2
     * @type {string}
     * @memberof GeoDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoDto
     */
    'streetAndNumber'?: string;
}
/**
 * 
 * @export
 * @interface GeoSuggestionsDto
 */
export interface GeoSuggestionsDto {
    /**
     * 
     * @type {Array<GeoDto>}
     * @memberof GeoSuggestionsDto
     */
    'locations'?: Array<GeoDto>;
}
/**
 * 
 * @export
 * @interface LocationBodyDto
 */
export interface LocationBodyDto {
    /**
     * 
     * @type {Array<EventHeaderDto>}
     * @memberof LocationBodyDto
     */
    'events'?: Array<EventHeaderDto>;
    /**
     * 
     * @type {string}
     * @memberof LocationBodyDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof LocationBodyDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {string}
     * @memberof LocationBodyDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationBodyDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'title'?: string;
    /**
     * 
     * @type {GeoDto}
     * @memberof LocationDto
     */
    'location'?: GeoDto;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'thumbImage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    'inRange'?: boolean;
    /**
     * 
     * @type {Array<EventHeaderDto>}
     * @memberof LocationDto
     */
    'events'?: Array<EventHeaderDto>;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof LocationDto
     */
    'contacts'?: Array<ContactDto>;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'headerImage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationDto
     */
    'galleryImages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LocationHeaderDto
 */
export interface LocationHeaderDto {
    /**
     * 
     * @type {string}
     * @memberof LocationHeaderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationHeaderDto
     */
    'title'?: string;
    /**
     * 
     * @type {GeoDto}
     * @memberof LocationHeaderDto
     */
    'location'?: GeoDto;
    /**
     * 
     * @type {string}
     * @memberof LocationHeaderDto
     */
    'thumbImage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationHeaderDto
     */
    'inRange'?: boolean;
}
/**
 * 
 * @export
 * @interface PageDto
 */
export interface PageDto {
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PageResponseDto
 */
export interface PageResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDto
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDto
     */
    'totalResults'?: number;
}
/**
 * 
 * @export
 * @interface PageResponseExtensionDto
 */
export interface PageResponseExtensionDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseExtensionDto
     */
    'totalResults'?: number;
}
/**
 * 
 * @export
 * @interface PeriodDto
 */
export interface PeriodDto {
    /**
     * 
     * @type {PeriodTypeDto}
     * @memberof PeriodDto
     */
    'type': PeriodTypeDto;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PeriodDto
     */
    'referenceIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'parentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PeriodDto
     */
    'locationIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PeriodDto
     */
    'repeatYear': number;
    /**
     * 
     * @type {number}
     * @memberof PeriodDto
     */
    'repeatMonth': number;
    /**
     * 
     * @type {number}
     * @memberof PeriodDto
     */
    'repeatWeek': number;
    /**
     * 
     * @type {number}
     * @memberof PeriodDto
     */
    'repeatDay': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'nextStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodDto
     */
    'lastEnd'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PeriodTypeDto = {
    Normal: 'Normal',
    Closed: 'Closed',
    Special: 'Special',
    Canceled: 'Canceled',
    Moved: 'Moved'
} as const;

export type PeriodTypeDto = typeof PeriodTypeDto[keyof typeof PeriodTypeDto];


/**
 * 
 * @export
 * @interface QuickSearchResponseDto
 */
export interface QuickSearchResponseDto {
    /**
     * 
     * @type {Array<GeoDto>}
     * @memberof QuickSearchResponseDto
     */
    'cities'?: Array<GeoDto>;
    /**
     * 
     * @type {Array<ArtistDto>}
     * @memberof QuickSearchResponseDto
     */
    'artists'?: Array<ArtistDto>;
    /**
     * 
     * @type {Array<LocationDto>}
     * @memberof QuickSearchResponseDto
     */
    'locations'?: Array<LocationDto>;
    /**
     * 
     * @type {Array<EventDto>}
     * @memberof QuickSearchResponseDto
     */
    'events'?: Array<EventDto>;
}
/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'price'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VirtualCategoryDto = {
    Upcoming: 'Upcoming',
    Sponsored: 'Sponsored',
    Subscribed: 'Subscribed',
    InterestedPerformer: 'InterestedPerformer',
    None: 'None'
} as const;

export type VirtualCategoryDto = typeof VirtualCategoryDto[keyof typeof VirtualCategoryDto];



/**
 * ArtistApi - axios parameter creator
 * @export
 */
export const ArtistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets artist by id
         * @param {string} id id of artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getArtistById', 'id', id)
            const localVarPath = `/artists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtistApi - functional programming interface
 * @export
 */
export const ArtistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArtistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets artist by id
         * @param {string} id id of artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArtistById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArtistById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArtistApi - factory interface
 * @export
 */
export const ArtistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArtistApiFp(configuration)
    return {
        /**
         * 
         * @summary gets artist by id
         * @param {string} id id of artist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistById(id: string, options?: any): AxiosPromise<ArtistDto> {
            return localVarFp.getArtistById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArtistApi - object-oriented interface
 * @export
 * @class ArtistApi
 * @extends {BaseAPI}
 */
export class ArtistApi extends BaseAPI {
    /**
     * 
     * @summary gets artist by id
     * @param {string} id id of artist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtistApi
     */
    public getArtistById(id: string, options?: AxiosRequestConfig) {
        return ArtistApiFp(this.configuration).getArtistById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all categories shown on the homne screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all categories shown on the homne screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultCategoriesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all categories shown on the homne screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultCategories(options?: any): AxiosPromise<DefaultCategoriesDto> {
            return localVarFp.getDefaultCategories(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @summary get all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategories(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all categories shown on the homne screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getDefaultCategories(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getDefaultCategories(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets event by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEventById', 'id', id)
            const localVarPath = `/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets events
         * @param {EventSearchDto} [eventSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (eventSearchDto?: EventSearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets event by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets events
         * @param {EventSearchDto} [eventSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(eventSearchDto?: EventSearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(eventSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary gets event by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(id: string, options?: any): AxiosPromise<EventDto> {
            return localVarFp.getEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets events
         * @param {EventSearchDto} [eventSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(eventSearchDto?: EventSearchDto, options?: any): AxiosPromise<EventSearchResponseDto> {
            return localVarFp.getEvents(eventSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary gets event by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventById(id: string, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).getEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets events
     * @param {EventSearchDto} [eventSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvents(eventSearchDto?: EventSearchDto, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).getEvents(eventSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets 10 autocompletions of searched location
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeoSuggestion: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getGeoSuggestion', 'query', query)
            const localVarPath = `/locations/geo/autocomplete/{query}`
                .replace(`{${"query"}}`, encodeURIComponent(String(query)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets location by id
         * @param {string} id id of location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationById', 'id', id)
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets 10 autocompletions of searched location
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeoSuggestion(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoSuggestionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeoSuggestion(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets location by id
         * @param {string} id id of location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary gets 10 autocompletions of searched location
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeoSuggestion(query: string, options?: any): AxiosPromise<GeoSuggestionsDto> {
            return localVarFp.getGeoSuggestion(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets location by id
         * @param {string} id id of location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationById(id: string, options?: any): AxiosPromise<LocationDto> {
            return localVarFp.getLocationById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary gets 10 autocompletions of searched location
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getGeoSuggestion(query: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getGeoSuggestion(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets location by id
     * @param {string} id id of location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationById(id: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary search for word
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quickSearch: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/quick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary search for word
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quickSearch(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuickSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quickSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary search for word
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quickSearch(query?: string, options?: any): AxiosPromise<QuickSearchResponseDto> {
            return localVarFp.quickSearch(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary search for word
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public quickSearch(query?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).quickSearch(query, options).then((request) => request(this.axios, this.basePath));
    }
}


