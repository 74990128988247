import { ArtistHeaderDto, CategoryDto, EventDto, VirtualCategoryDto } from "@/api";

export function getCategoryId( category: any ) {
    return category.id ? category.id : category;
}

export function getCategorySlug( category: any ) {
    return category.slug ? category.slug : category;
}

export function getLocationName( ids: string[], event, i18n, length ) {
    if( !event.locations ) {
        return "";
    }

    let eventLoc;
    if( ids.length > 0 )
        eventLoc = event.locations.filter( l => ids.includes( l.id ) );
    else
        eventLoc = event.locations;

    let result = "";
    let tooLong = false;
    let additionalLocations = 0;
    for( const loc of eventLoc ) {
        let text = loc.title + ' (' + loc.location.city + ')';
        if( result.length + text.length > length ) {
            tooLong = true;
        }
        if( tooLong ) {
            additionalLocations += 1;
        } else {
            result += text + ', ';
        }
    }

    result = andXMore( result, additionalLocations, i18n );

    return result;
}

function andXMore( result: string, counter: number, i18n ) {
    let pos = result.lastIndexOf( ', ' );
    if( pos > 0 )
        result = result.substring( 0, pos );

    if( counter == 0 ) {
        pos = result.lastIndexOf( ', ' );
        if( pos > 0 )
            result = result.substring( 0, pos ) + ' & ' + result.substring( pos + 1 );
    } else {
        result += i18n.t( 'common.andXMore', { x: counter < 0 ? "" : counter.toString() } );
    }
    return result;
}

export function getPerformerTitle( ids: string[], event: EventDto, i18n, length ): string {
    if( !event.performers ) {
        return "";
    }

    let eventArtists;
    if( ids.length > 0 )
        eventArtists = event.performers.filter( l => ids.includes( l.id ) );
    else
        eventArtists = event.performers;

    let result = "";
    let tooLong = false;
    let additionalArtists = 0;
    for( const performer of eventArtists ) {
        let text = performer.title;
        if( result.length + text.length > length ) {
            tooLong = true;
        }
        if( tooLong ) {
            additionalArtists += 1;
        } else {
            result += text + ', ';
        }
    }
    result = andXMore( result, additionalArtists, i18n );

    return result;
}


export function getProfessionsTitle( professions: string[], i18n, length ): string {

    let result = "";
    let tooLong = false;
    for( const profession of professions ) {
        let text = profession;
        if( result.length + text.length > length ) {
            tooLong = true;
        }
        if( !tooLong ) {
            result += text + ', ';
        }
    }
    result = andXMore( result, -1, i18n );

    return result;
}