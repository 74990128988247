

import { defineComponent, PropType, ref, Ref } from "vue";
import Gallery from "@/components/general/Gallery.vue";

export default defineComponent( {
    name: 'ModalImagesGallery',
    components: { Gallery },
    props: {
        galleryImages: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        id: {
            type: String
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {

        function setCurrImage( img: string ) {
            emit( 'change', img );
        }

        return {
            setCurrImage
        };
    }
} );
