import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table table-bordered table-light table-fixed table-p-1" }
const _hoisted_2 = { colspan: "7" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "m-auto d-flex py-2" }
const _hoisted_5 = { class: "px-2 h5 my-auto w-175px text-center" }
const _hoisted_6 = { class: "table-active" }
const _hoisted_7 = { class: "table-active" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "fw-bold" }
const _hoisted_10 = { class: "pe-1" }
const _hoisted_11 = { class: "font-monospace" }
const _hoisted_12 = { class: "font-monospace" }
const _hoisted_13 = { class: "ps-1 s-split flex-no-overflow" }
const _hoisted_14 = ["title"]
const _hoisted_15 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("i", {
                class: "bi bi-chevron-double-left cursor-pointer my-auto",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currYear -= 1))
              }),
              _createElementVNode("i", {
                class: "bi bi-chevron-left cursor-pointer my-auto",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevMonth && _ctx.prevMonth(...args)))
              }),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t( 'date.month.m' + _ctx.currMonth )) + " " + _toDisplayString(_ctx.currYear), 1),
              _createElementVNode("i", {
                class: "bi bi-chevron-right cursor-pointer my-auto",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextMonth && _ctx.nextMonth(...args)))
              }),
              _createElementVNode("i", {
                class: "bi bi-chevron-double-right cursor-pointer my-auto",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.currYear += 1))
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, _toDisplayString(_ctx.$t( 'date.days.mo' )), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t( 'date.days.tu' )), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t( 'date.days.we' )), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t( 'date.days.th' )), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.$t( 'date.days.fr' )), 1),
        _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t( 'date.days.sa' )), 1),
        _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t( 'date.days.su' )), 1)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weeksOfMonth, (weekOfMonth) => {
        return (_openBlock(), _createElementBlock("tr", { key: weekOfMonth }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (dayOfWeek) => {
            return _createElementVNode("td", {
              key: dayOfWeek,
              class: _normalizeClass([{'table-active': dayOfWeek > 5}, "calendar-element text-small"])
            }, [
              (_ctx.dayOfMonth(dayOfWeek, weekOfMonth) > 0 && _ctx.dayOfMonth(dayOfWeek, weekOfMonth) <= _ctx.currMonthDays)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dayOfMonth( dayOfWeek, weekOfMonth )), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datesOfDate(_ctx.dayOfMonth( dayOfWeek, weekOfMonth )), (date) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: date,
                        class: "calendar-active-element d-flex"
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.moment( date[ 0 ] ).format( _ctx.$t( 'moment.formatTime' ) )), 1),
                          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.moment( date[ 1 ] ).format( _ctx.$t( 'moment.formatTime' ) )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", {
                            class: "text-truncate",
                            title: date[ 2 ].name
                          }, _toDisplayString(date[ 2 ].name), 9, _hoisted_14),
                          _createElementVNode("div", {
                            class: "text-truncate",
                            title: _ctx.getLocationName( date[3].map(l => l.id), _ctx.event, _ctx.$i18n, 30 )
                          }, _toDisplayString(_ctx.getLocationName( date[ 3 ].map( l => l.id ), _ctx.event, _ctx.$i18n, 30 )), 9, _hoisted_15)
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          }), 64))
        ]))
      }), 128))
    ])
  ]))
}