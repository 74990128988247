

import { defineComponent, getCurrentInstance, onMounted, PropType, ref, Ref, watch } from "vue";
import { EventApi, EventDto, LocationDto } from "@/api";
import ModalImagesGallery from "@/components/general/ModalImagesGallery.vue";
import FullSizeGalleryModal from "@/components/general/FullSizeGalleryModal.vue";
import ModalEventGallery from "@/components/general/ModalEventGallery.vue";
import ModalContacts from "@/components/general/ModalContacts.vue";
import { ApiService } from "@/services/ApiService";
import { setTitle } from "@/utils/Utils";

interface Props {
    location: LocationDto,
    id: string
}

export default defineComponent( {
    name: 'LocationModal',
    components: { ModalImagesGallery, FullSizeGalleryModal, ModalEventGallery, ModalContacts },
    props: {
        location: {
            type: Object as PropType<LocationDto>
        },
        parentModal: {
            type: String,
            default: () => ""
        },
        id: {
            type: String
        }
    },
    setup( props: Props ) {
        const updateCount: Ref<number> = ref( 0 );
        const currImg: Ref<string> = ref( "" );
        const currEvent: Ref<EventDto> = ref( null );
        const eventApi = ApiService.wrap( EventApi );
        const app = getCurrentInstance();

        onMounted( () => {
            const currModal = document.getElementById( props.id );
            currModal.addEventListener( 'shown.bs.modal', function() {
                updateCount.value++;
                window.history.pushState( props.id, 'Title', '/location/' + props.location.id );
                setTitle( props.location.title );
            } );

            currModal.addEventListener( 'hidden.bs.modal', function() {
                app.appContext.config.globalProperties.emitter.emit( "closeModal" );
            } );
        } );

        function setCurrImage( img: string ) {
            currImg.value = img;
        }

        function setCurrEvent( id: string ) {
            eventApi.getEventById( id ).then( r => {
                currEvent.value = r.data;
            } ).catch( ApiService.defaultErrorHandler );
        }

        return {
            updateCount,
            currImg,
            currEvent,
            setCurrImage,
            setCurrEvent
        };
    }
} );
