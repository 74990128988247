import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  ref: "container"
}
const _hoisted_2 = { class: "m-0" }
const _hoisted_3 = {
  key: 0,
  class: "bi bi-chevron-up ms-1 text-12"
}
const _hoisted_4 = {
  key: 1,
  class: "bi bi-chevron-down ms-1 text-12"
}
const _hoisted_5 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_6 = { class: "sc-categories py-3 px-4" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "ms-2 px-2 my-auto sc-header-search d-flex align-items-center" }
const _hoisted_11 = { class: "position-relative" }
const _hoisted_12 = { class: "row px-4" }
const _hoisted_13 = {
  key: 0,
  class: "col px-2"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "ms-2" }
const _hoisted_17 = {
  key: 1,
  class: "col"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "ms-2" }
const _hoisted_21 = {
  key: 2,
  class: "col"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "ms-2" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 1,
  class: "row d-flex px-4 pb-2"
}
const _hoisted_27 = { colspan: "2" }
const _hoisted_28 = { class: "mb-1" }
const _hoisted_29 = { class: "fw-bold fs-5 my-auto" }
const _hoisted_30 = { class: "my-auto ms-2" }
const _hoisted_31 = { class: "ps-3" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "bg-blue py-1 d-flex w-100 cursor-pointer" }
const _hoisted_37 = { class: "sc-day" }
const _hoisted_38 = { class: "sc-year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventDetailModal = _resolveComponent("EventDetailModal")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#globalModals" }, [
      _createVNode(_component_EventDetailModal, {
        id: "quicksearchEventDetailModal",
        event: _ctx.currEvent
      }, null, 8, ["event"])
    ])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "ms-2 px-2 my-auto d-flex align-items-center text-light cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowCategories && _ctx.toggleShowCategories(...args))),
        tabindex: "-1",
        id: "headerCategoriesDropdown",
        onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShowCategories && _ctx.toggleShowCategories(...args)))
      }, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t( 'category.categories' )), 1),
        (_ctx.showCategories)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : (_openBlock(), _createElementBlock("i", _hoisted_4))
      ], 32),
      (_ctx.showCategories)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVirtualCategories(), (category) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "cursor-pointer",
                  onClick: ($event: any) => (_ctx.searchForVirtualCategory(category)),
                  key: category
                }, _toDisplayString(_ctx.$t( 'category.' + category )), 9, _hoisted_7))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: category.id
                }, [
                  _createElementVNode("div", {
                    class: "cursor-pointer",
                    onClick: ($event: any) => (_ctx.searchForCategory(category.id))
                  }, _toDisplayString(_ctx.$t( 'category.' + category.slug )), 9, _hoisted_8),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.children, (subCategory) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "cursor-pointer text-muted-gray ms-1",
                      onClick: ($event: any) => (_ctx.searchForCategory(subCategory.id)),
                      key: subCategory.id
                    }, _toDisplayString(_ctx.$t( 'category.' + subCategory.slug )), 9, _hoisted_9))
                  }), 128))
                ], 64))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512),
    _createElementVNode("div", {
      class: "d-flex flex-column",
      onFocusin: _cache[8] || (_cache[8] = ($event: any) => (_ctx.show = true)),
      onFocusout: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
      ref: "container"
    }, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchByWord && _ctx.searchByWord(...args)), ["prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query) = $event)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show = true))
          }, null, 544), [
            [_vModelText, _ctx.query]
          ])
        ], 32),
        _createElementVNode("span", {
          class: "material-icons cursor-pointer",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.searchByWord && _ctx.searchByWord(...args)))
        }, " search ")
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.quickresult && _ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "sc-quickresults shadow pt-2",
              tabindex: "1",
              onFocusout: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
            }, [
              _createElementVNode("div", _hoisted_12, [
                (_ctx.quickresult.events.length !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'common.events' )), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickresult.events.slice(0,4), (event) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "d-flex py-1 cursor-pointer",
                          key: event.id,
                          onClick: ($event: any) => (_ctx.selectEvent(event)),
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#quicksearchEventDetailModal"
                        }, [
                          _createElementVNode("img", {
                            src: event.thumbImage,
                            class: "sc-quick-thumb"
                          }, null, 8, _hoisted_15),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", null, _toDisplayString(event.name), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.locationName( event )), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.artistName( event )), 1)
                          ])
                        ], 8, _hoisted_14))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.quickresult.artists.length !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'common.artists' )), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickresult.artists.slice(0,4), (artist) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "d-flex py-1 cursor-pointer",
                          key: artist.id,
                          onClick: ($event: any) => (_ctx.searchForArtist(artist))
                        }, [
                          _createElementVNode("img", {
                            src: artist.thumbImage,
                            class: "sc-quick-thumb"
                          }, null, 8, _hoisted_19),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", null, _toDisplayString(artist.title), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.professionName( artist.profession )), 1)
                          ])
                        ], 8, _hoisted_18))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.quickresult.locations.length !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'common.locations' )), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickresult.locations.slice(0,4), (location) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "d-flex py-1 cursor-pointer",
                          key: location.id,
                          onClick: ($event: any) => (_ctx.searchForLocation(location))
                        }, [
                          _createElementVNode("img", {
                            src: location.thumbImage,
                            class: "sc-quick-thumb"
                          }, null, 8, _hoisted_23),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", null, _toDisplayString(location.title), 1),
                            _createElementVNode("div", null, _toDisplayString(location.location.streetAndNumber + ', ' + location.location.city), 1),
                            _createElementVNode("div", null, _toDisplayString(location.location.federalState + '-' + location.location.country), 1)
                          ])
                        ], 8, _hoisted_22))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.quickresult.cities.length > 0)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_25))
                : _createCommentVNode("", true),
              (_ctx.quickresult.cities.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_26, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("td", _hoisted_27, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.$t( 'common.cities' )), 1)
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickresult.cities.slice(0,6), (city) => {
                        return (_openBlock(), _createElementBlock("tr", { key: city }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("span", _hoisted_29, _toDisplayString(city.city), 1),
                              _createElementVNode("span", _hoisted_30, _toDisplayString(city.district + ' ' + city.federalState + ' ' + city.country), 1)
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_31, [
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-light me-2",
                              onClick: ($event: any) => (_ctx.searchForGeoLocation(city,10))
                            }, " 10km", 8, _hoisted_32),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-light me-2",
                              onClick: ($event: any) => (_ctx.searchForGeoLocation(city,25))
                            }, " 25km", 8, _hoisted_33),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-light me-2",
                              onClick: ($event: any) => (_ctx.searchForGeoLocation(city,50))
                            }, " 50km", 8, _hoisted_34),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-light me-2",
                              onClick: ($event: any) => (_ctx.searchForGeoLocation(city,100))
                            }, " 100km", 8, _hoisted_35)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("span", {
                  class: "mx-auto text-dark",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.searchByWord && _ctx.searchByWord(...args)))
                }, _toDisplayString(_ctx.$t( 'search.showAll' )), 1)
              ])
            ], 32))
          : _createCommentVNode("", true)
      ])
    ], 544),
    _createElementVNode("div", {
      class: "sc-date my-auto ms-2",
      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.searchDatePicker.openMenu()))
    }, [
      _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.moment( _ctx.date ).format( _ctx.$t( 'moment.formatDay' ) )), 1),
      _createElementVNode("div", _hoisted_38, [
        _createElementVNode("div", null, _toDisplayString(_ctx.date.getFullYear()), 1)
      ]),
      _createVNode(_component_Datepicker, {
        enableTimePicker: false,
        dark: true,
        ref: "searchDatePicker",
        "auto-apply": true,
        menuClassName: "dp-header-menu",
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.date) = $event))
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}