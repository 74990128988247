

import { defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";
import { EventDto, PeriodDto } from "@/api";
import { getNextDates, PeriodStartToDate } from "@/utils/PeriodUtils";
import Calendar from "@/components/event/NextEvents/Calendar.vue";
import List from "@/components/event/NextEvents/List.vue";

enum Mode {
    Calendar,
    List
}

export default defineComponent( {
    name: 'NextEvents',
    components: { List, Calendar },
    props: {
        event: {
            type: Object as PropType<EventDto>,
            default: () => null
        }
    },
    setup( props ) {
        const mode: Ref<Mode> = ref( Mode.List );
        const firstEvent: Ref<PeriodDto> = ref( null );

        onMounted( () => {
            init();
        } );

        function init() {
            const events = getNextDates( props.event.periods, 1 );
            if( events.length > 0 ) {
                firstEvent.value = events[0];
            }
        }

        watch( () => props.event, function() {
            init();
        } );

        return {
            Mode,
            mode,
            firstEvent,
            PeriodStartToDate
        };
    }
} );
