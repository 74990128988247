

import { defineComponent, onMounted, Ref, ref, watch } from "vue";
import { CategoryApi, EventApi, EventDto, EventSearchDto, EventSearchResponseDto } from "@/api";
import EventDetailModal from "@/components/event/EventDetailModal.vue";
import EventMap from "@/components/map/EventMap.vue";
import EventGroup from "@/components/event/EventGroup.vue";
import { ApiService } from "@/services/ApiService";
import { useStore } from "vuex";
import { getCategoryId } from "@/utils/EventUtils";
import { setQueryStringParameter } from "@/utils/Utils";
import EventsFullPage from "@/components/event/EventsFullPage.vue";

export default defineComponent( {
    name: 'Home',
    components: { EventsFullPage, EventMap, EventDetailModal, EventGroup },
    props: {},
    setup: function() {
        const currEvent: Ref<EventDto> = ref( null );
        const eventSearch: Ref<EventSearchResponseDto> = ref( null );
        const eventApi = ApiService.wrap( EventApi );
        const categoryApi = ApiService.wrap( CategoryApi );
        const store = useStore();

        onMounted( () => {
            getEvents();
        } );

        async function getEvents() {
            const params = new URLSearchParams( window.location.search );
            let json: EventSearchDto;
            if( params.has( "q" ) ) {
                json = JSON.parse( params.get( "q" ) );
            } else {
                const defaultCategories = await categoryApi.getDefaultCategories();
                json = {
                    page: {
                        page: 0,
                        size: 30
                    },
                    virtualCategories: defaultCategories.data.virtuellCategories,
                    categories: defaultCategories.data.categories.map( i => i.id )
                };
            }
            eventApi.getEvents( json ).then( r => {
                store.commit( 'setEventSearchResult', r.data );
            } ).catch( ApiService.defaultErrorHandler );
        }

        function selectEvent( event: any ) {
            currEvent.value = event;
        }

        watch( () => store.state.eventSearchResult, function() {
            eventSearch.value = store.state.eventSearchResult;
        } );

        return {
            eventSearch,
            currEvent,
            selectEvent,
            getCategoryId
        };
    }
} );
