

import { defineComponent } from "vue";
import HeaderContainer from "@/components/header/HeaderContainer.vue";
import BodyContainer from "@/components/BodyContainer.vue";
import { setTitle } from "@/utils/Utils";

export default defineComponent( {
    name: 'App',
    components: { BodyContainer, HeaderContainer},
    props: {},
    setup() {
        setTitle( "" );
    }
} );
