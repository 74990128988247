

import { defineComponent, onMounted, onUpdated, ref, Ref } from "vue";

export default defineComponent( {
    name: 'Gallery',
    components: {},
    props: {
        id: {
            type: String
        },
        singleLine: {
            type: Boolean
        },
        galleryClass: {
            type: String,
            default: () => ""
        },
        noGrid: {
            type: Boolean,
            default: () => false
        }
    },
    emits: [ "selectEvent" ],
    setup( props, { emit } ) {
        const firstElementIndex: Ref<number> = ref( 0 );
        const position: Ref<number> = ref( 0 );
        const navigatorHeight: Ref<number> = ref( 0 );
        const isAtEnd: Ref<boolean> = ref( false );
        let container: HTMLElement;
        let defaultPosition: number;

        onMounted( () => {
            container = document.getElementById( props.id );
            defaultPosition = container.firstElementChild.getBoundingClientRect().left;
            navigatorHeight.value = container.firstElementChild.clientHeight;
            calcIsAtEnd();
        } );

        onUpdated( () => {
            calcIsAtEnd();
        } );

        function calcIsAtEnd() {
            if( container ) {
                isAtEnd.value = container.lastElementChild.getBoundingClientRect().right <= container.parentElement.getBoundingClientRect().right;
            }
        }

        function elementsShown(): number {
            let count = 0;
            for( let i = firstElementIndex.value; i < container.childElementCount; i++ ) {
                if( container.children[i].getBoundingClientRect().right <= container.parentElement.getBoundingClientRect().right ) {
                    count++;
                } else {
                    break;
                }
            }
            return count;
        }

        function toRight() {
            const elements = elementsShown();
            position.value -= firstElementIndex.value + elements <= container.childElementCount - elements ?
                    container.children[firstElementIndex.value + elements].getBoundingClientRect().left :
                    container.children[container.childElementCount - elements].getBoundingClientRect().left;
            position.value += defaultPosition;
            firstElementIndex.value = Math.min( container.childElementCount - elements, firstElementIndex.value + elements );
            if( firstElementIndex.value != 0 && props.noGrid ) {
                position.value += 48;
            }
            container.style.marginLeft = position.value.toString() + "px";
            calcIsAtEnd();
        }

        function toLeft() {
            const elements = elementsShown();
            if( firstElementIndex.value - elements > 0 ) {
                position.value -= container.children[firstElementIndex.value - elements].getBoundingClientRect().left - defaultPosition;
            } else {
                position.value = 0;
            }
            firstElementIndex.value = Math.max( 0, firstElementIndex.value - elements );
            if( firstElementIndex.value != 0 && props.noGrid ) {
                position.value += 48;
            }
            container.style.marginLeft = position.value.toString() + "px";
            calcIsAtEnd();
        }

        return {
            firstElementIndex,
            isAtEnd,
            navigatorHeight,
            toRight,
            toLeft
        };
    }
} );
