

import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";
import { EventDto, LocationHeaderDto, PeriodDto } from "@/api";
import { getNextDates, PeriodEndToDate, PeriodStartToDate } from "@/utils/PeriodUtils";
import moment from "moment";
import { getLocationName } from "@/utils/EventUtils";

export default defineComponent( {
    name: 'Calendar',
    components: {},
    props: {
        event: {
            type: Object as PropType<EventDto>,
            default: () => null
        },
        focusDay: {
            type: Object as PropType<Date>,
            default: () => null
        }
    },
    setup( props ) {
        const upcomingDates: Ref<[ Date, Date, EventDto, LocationHeaderDto[] ][]> = ref( [] );
        const currMonth: Ref<number> = ref( 0 );
        const currYear: Ref<number> = ref( 0 );
        const currMonthDays: Ref<number> = ref( 0 );
        const currStartWeekday: Ref<number> = ref( 0 );
        const weeksOfMonth: Ref<number> = ref( 0 );
        const pageSize = 10;

        onMounted( () => {
            init();
        } );

        watch( () => currMonth.value, function() {
            loadEvents();
        } );

        watch( () => currYear.value, function() {
            loadEvents();
        } );

        watch( () => props.event, function() {
            loadEvents();
        } );

        watch( () => props.focusDay, function() {
            init();
        } );

        function init() {
            const firstDay = moment( props.focusDay );
            currYear.value = firstDay.year();
            currMonth.value = firstDay.month();
            loadEvents();
        }

        function loadEvents() {
            let finished = false;
            let firstDayOfTheMonth = moment( new Date( currYear.value, currMonth.value ) );
            let lastDate = firstDayOfTheMonth.toDate();
            currMonthDays.value = firstDayOfTheMonth.daysInMonth();
            currStartWeekday.value = firstDayOfTheMonth.weekday();
            let upcomingEvents: PeriodDto[] = [];

            while( !finished && props.event ) {
                const upcoming = getNextDates( props.event.periods, pageSize, firstDayOfTheMonth.add( -firstDayOfTheMonth.weekday(), 'd' ).toDate() );
                upcomingEvents = upcomingEvents.concat( upcoming );
                finished = upcoming.length != pageSize;
                if( !finished ) {
                    const lastDateMoment = moment( PeriodStartToDate( upcoming[upcoming.length - 1] ) ).add( 1, 'm' );
                    lastDate = lastDateMoment.toDate();
                    finished = lastDateMoment.year() > currYear.value || lastDateMoment.month() > currMonth.value;
                }
            }

            weeksOfMonth.value = Math.ceil( ( currMonthDays.value + currStartWeekday.value - 1 ) / 7 );
            let event = props.event;

            const upcomingDatesTmp: [ Date, Date, EventDto, LocationHeaderDto[] ] [] =
                    upcomingEvents.map( e => [ PeriodStartToDate( e ), PeriodEndToDate( e ), event, event.locations.filter( l => e.locationIds.includes( l.id ) ) ] );

            upcomingDates.value = [];
            for( const date of upcomingDatesTmp.filter( d => moment( d[0] ).month() == currMonth.value ) ) {
                let firstDay = date[0];
                let lastDay = date[1];
                if( !lastDay || !firstDay || firstDay.toDateString() == lastDay.toDateString() ) {
                    upcomingDates.value.push( date );
                } else if( lastDay.getTime() < firstDay.getTime() ) {
                    date[1] = null;
                    upcomingDates.value.push( date );
                } else {
                    upcomingDates.value.push( [ date[0], moment( date[0] ).endOf( 'd' ).toDate(), date[2], date[3] ] );
                    let currDay = moment( firstDay ).add( 1, 'd' );
                    while( currDay.toDate().getTime() < lastDay.getTime() ) {
                        upcomingDates.value.push( [ currDay.startOf( 'd' ).toDate(), currDay.endOf( 'd' ).toDate(), date[2], date[3] ] );
                        currDay = currDay.add( 1, 'd' );
                    }
                    upcomingDates.value.push( [ currDay.startOf( 'd' ).toDate(), date[1], date[2], date[3] ] );
                }
            }
        }

        function dayOfMonth( dayOfWeek, weekOfMonth ) {
            return dayOfWeek + ( weekOfMonth - 1 ) * 7 - currStartWeekday.value;
        }

        function nextMonth() {
            if( currMonth.value == 11 ) {
                currMonth.value = 1;
                currYear.value += 1;
            } else {
                currMonth.value += 1;
            }
        }

        function prevMonth() {
            if( currMonth.value == 0 ) {
                currMonth.value = 12;
                currYear.value -= 1;
            } else {
                currMonth.value -= 1;
            }
        }

        function datesOfDate( dayOfMonth: number ) {
            const currDay = new Date( currYear.value, currMonth.value, dayOfMonth );
            const dates: [ Date, Date, EventDto, LocationHeaderDto[] ] [] = [];
            for( const date of upcomingDates.value ) {
                if( ( date[0] && date[0].toDateString() == currDay.toDateString() ) || ( !date[0] && date[1] && date[1].toDateString() == currDay.toDateString() ) ) {
                    dates.push( date );
                }
            }
            return dates;
        }

        return {
            moment,
            currMonthDays,
            currStartWeekday,
            weeksOfMonth,
            currYear,
            currMonth,
            getLocationName,
            datesOfDate,
            dayOfMonth,
            prevMonth,
            nextMonth
        };
    }
} );
