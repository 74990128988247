

import { defineComponent, getCurrentInstance, onMounted, PropType, Ref, ref, watch } from "vue";
import {
    ArtistApi,
    ArtistDto,
    EventApi,
    EventDto,
    EventSearchDto,
    LocationApi,
    LocationDto,
    VirtualCategoryDto
} from "@/api";
import Gallery from "@/components/general/Gallery.vue";
import ModalImagesGallery from "@/components/general/ModalImagesGallery.vue";
import FullSizeGalleryModal from "@/components/general/FullSizeGalleryModal.vue";
import ModalContacts from "@/components/general/ModalContacts.vue";
import ModalLocationGallery from "@/components/general/ModalLocationGallery.vue";
import NextEvents from "@/components/event/NextEvents/NextEvents.vue";
import { ApiService } from "@/services/ApiService";
import { getCategoryId, getCategorySlug } from "@/utils/EventUtils";
import { useStore } from "vuex";
import { setQueryStringParameter, setTitle } from "@/utils/Utils";
import { useI18n } from "vue-i18n";

interface Props {
    event: EventDto,
    id: string
}

export default defineComponent( {
    name: 'EventDetailModal',
    components: { NextEvents, ModalLocationGallery, ModalImagesGallery, Gallery, FullSizeGalleryModal, ModalContacts },
    props: {
        event: {
            type: Object as PropType<EventDto>
        },
        id: {
            type: String
        },
        parentModal: {
            type: String,
            default: () => ""
        }
    },
    setup( props: Props ) {
        const updateCount: Ref<number> = ref( 0 );
        const currArtist: Ref<ArtistDto> = ref( null );
        const currLocation: Ref<LocationDto> = ref( null );
        const currImg: Ref<string> = ref( "" );
        const locApi = ApiService.wrap( LocationApi );
        const artApi = ApiService.wrap( ArtistApi );
        const store = useStore();
        const eventApi = ApiService.wrap( EventApi );
        const app = getCurrentInstance();

        onMounted( () => {
            const currModal = document.getElementById( props.id );
            currModal.addEventListener( 'shown.bs.modal', function() {
                window.history.pushState( props.id, 'Title', '/event/' + props.event.id );
                setTitle( props.event.name );
                updateCount.value++;
            } );

            currModal.addEventListener( 'hidden.bs.modal', function() {
                app.appContext.config.globalProperties.emitter.emit( "closeModal" );
            } );
        } );


        function setArtist( id: string ) {
            artApi.getArtistById( id ).then( r => {
                currArtist.value = r.data;
            } ).catch( ApiService.defaultErrorHandler );
        }

        function setLocation( id: string ) {
            locApi.getLocationById( id ).then( r => {
                currLocation.value = r.data;
            } ).catch( ApiService.defaultErrorHandler );
        }

        function setCurrImage( img: string ) {
            currImg.value = img;
        }

        function search( json: EventSearchDto ) {
            setQueryStringParameter( "q", JSON.stringify( json ) );
            eventApi.getEvents( json ).then( r => {
                store.commit( 'setEventSearchResult', r.data );
            } ).catch( ApiService.defaultErrorHandler );
        }

        function searchForCategory( category: string ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                since: store.state.searchDate,
                categories: [ category ]
            };
            document.getElementById( props.id + 'Close' ).click();
            search( json );
        }

        function searchForVirtualCategory( category: VirtualCategoryDto ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                since: store.state.searchDate,
                virtualCategories: [ category ]
            };
            search( json );
        }

        return {
            updateCount,
            currArtist,
            currLocation,
            currImg,
            setArtist,
            setLocation,
            setCurrImage,
            getCategoryId,
            getCategorySlug,
            searchForCategory,
            searchForVirtualCategory
        };
    }
} );
