

import { defineComponent, onUpdated, PropType, ref, Ref } from "vue";
import Gallery from "@/components/general/Gallery.vue";
import { EventHeaderDto } from "@/api";

export default defineComponent( {
    name: 'ModalEventGallery',
    components: { Gallery },
    props: {
        events: {
            type: Array as PropType<EventHeaderDto[]>,
            default: () => []
        },
        id: {
            type: String
        }
    },
    emits: [ "change" ],
    setup( props, { emit } ) {

        function setCurrEvent( id: string ) {
            emit( 'change', id );
        }

        return {
            setCurrEvent
        };
    }
} );
