export abstract class ConfigService {
    public static getFallbackLang() {
        return process.env.VUE_APP_DEFAULT_LANG;
    }

    static getHubBaseUrl() {
        return process.env.VUE_APP_HUB_BASE_URL;
    }

    static getGeoLocationRefreshTime() {
        return process.env.VUE_APP_GEO_LOCATION_REFRESH_TIME;
    }

    static getLoginPage() {
        return process.env.VUE_APP_LOGIN_PAGE;
    }

    static getTitle() {
        return process.env.VUE_APP_TITLE;
    }
}