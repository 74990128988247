

import { defineComponent, PropType } from "vue";
import { ContactDto } from "@/api";

export default defineComponent( {
    name: 'ModalContacts',
    components: {},
    props: {
        contacts: {
            type: Array as PropType<ContactDto[]>,
            default: () => []
        }
    },
    setup() {
    }
} );
