import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "eventMap",
  style: {"height":"800px"}
}
const _hoisted_2 = {
  key: 0,
  class: "position-absolute start-0 top-0 m-4 overlay"
}
const _hoisted_3 = { class: "h4 rounded-pill px-3 bg-white text-dark" }
const _hoisted_4 = { class: "position-absolute end-0 top-0 m-4 overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentMapPart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentMapPart.properties.NAME_LATN + ': ' + _ctx.currentMapPart.events), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("i", {
        class: "bi bi-globe2 h2 rounded-pill px-1 bg-white text-dark",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
      })
    ])
  ]))
}