import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "h-100 d-flex align-items-start flex-column" }
const _hoisted_2 = { class: "bg-white px-2" }
const _hoisted_3 = { class: "my-1" }
const _hoisted_4 = { class: "bg-white px-2 mt-1 text-small" }
const _hoisted_5 = { class: "bg-white px-2 mt-1 text-small" }
const _hoisted_6 = { class: "bg-white px-2 mt-auto" }
const _hoisted_7 = {
  key: 0,
  class: "bg-white px-2 mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.earliestLocation)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "event-card overflow-hidden",
        style: _normalizeStyle('background: url(' + _ctx.event.thumbImage + ')' )
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.event.name), 1)
          ]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getEventPerformerTitle()), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.earliestLocation.location.federalState) + ", " + _toDisplayString(_ctx.earliestLocation.location.city) + ": " + _toDisplayString(_ctx.earliestLocation.title), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.earliestDateString), 1),
          (_ctx.dateSpan)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.dateSpan), 1))
            : _createCommentVNode("", true)
        ])
      ], 4))
    : _createCommentVNode("", true)
}