import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "d-flex mb-1 h5 cursor-pointer" }
const _hoisted_4 = {
  key: 1,
  class: "w-100 d-flex bg-light mt-2"
}
const _hoisted_5 = { class: "m-auto h3 fw-bold text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_ctx.event && _ctx.firstEvent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t( 'common.dates' )), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["tabs", { active: _ctx.mode === _ctx.Mode.List}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode = _ctx.Mode.List))
          }, _toDisplayString(_ctx.$t( 'time.list' )), 3),
          _createElementVNode("span", {
            class: _normalizeClass(["tabs", { active: _ctx.mode === _ctx.Mode.Calendar}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode = _ctx.Mode.Calendar))
          }, _toDisplayString(_ctx.$t( 'time.calendar' )), 3)
        ]),
        (_ctx.mode === _ctx.Mode.List)
          ? (_openBlock(), _createBlock(_component_List, {
              key: 0,
              event: _ctx.event
            }, null, 8, ["event"]))
          : _createCommentVNode("", true),
        (_ctx.mode === _ctx.Mode.Calendar)
          ? (_openBlock(), _createBlock(_component_Calendar, {
              key: 1,
              event: _ctx.event,
              "focus-day": _ctx.PeriodStartToDate( _ctx.firstEvent )
            }, null, 8, ["event", "focus-day"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t( 'time.notFound' )), 1)
      ]))
}