

import { defineComponent, onMounted, ref, Ref } from "vue";
import L from "leaflet";
import * as topojson from "topojson";
import topoData from "../../../public/data/eu_map.json";
import mockEvents from "../../../public/data/MOCK_EVENTS.json";
import { useI18n } from "vue-i18n";

L.TopoJSON = L.GeoJSON.extend( {
    addData: function( jsonData: any ) {
        if( jsonData.type === "Topology" ) {
            for( const key in jsonData.objects ) {
                const geojson = topojson.feature( jsonData, jsonData.objects[key] );
                L.GeoJSON.prototype.addData.call( this, geojson );
            }
        } else {
            L.GeoJSON.prototype.addData.call( this, jsonData );
        }
    }
} );


export default defineComponent( {
    name: 'EventMap',
    components: {},
    props: {},
    setup() {
        const map: Ref<any> = ref( null );
        const topoLayer: Ref<any> = ref( null );
        const zoomLayer: Ref<any> = ref( null );
        const currentMapPart: Ref<any> = ref( null );
        const markers: Ref<any[]> = ref( [] );
        const layer: Ref<number> = ref( 0 );
        const i18n = useI18n();

        function mapStyle( feature ) {
            return {
                fillColor: getColor( feature.events ),
                weight: 2,
                opacity: 1,
                color: 'black',
                fillOpacity: getOpacity( feature.properties.LEVL_CODE )
            };
        }

        function getOpacity( featureLevel: number ) {
            if( featureLevel == layer.value ) {
                return 0.7;
            }
            return 0.1;
        }

        function getColor( events: number ) {
            if( events == 0 )
                return 'white';
            if( events < 5 )
                return '#c5cae9';
            if( events < 10 )
                return '#9fa8da';
            if( events < 25 )
                return '#7986cb';
            if( events < 50 )
                return '#5c6bc0';
            if( events < 100 )
                return '#3f51b5';
            if( events < 250 )
                return '#3949ab';
            return '#303f9f';
        }

        onMounted( () => {
            map.value = L.map( 'eventMap', { zoomControl: false, scrollWheelZoom: false } );
            map.value.invalidateSize();
            map.value.doubleClickZoom.disable();
            map.value.dragging.disable();

            topoLayer.value = new L.TopoJSON( null, {
                style: mapStyle,
                onEachFeature: onEachFeature,
                attribution: i18n.t( 'map.source' )
            } );
            zoomLayer.value = new L.TopoJSON( null );
            topoLayer.value.addTo( map.value );

            filterTopoLayer( "AT" );
        } );

        function filterTopoLayer( parentCode ) {
            const data = JSON.parse( JSON.stringify( topoData ) );
            data.objects[Object.keys( data.objects )[0]].geometries = data.objects[Object.keys( data.objects )[0]].geometries.filter( ( element ) =>
                    ( element.properties.LEVL_CODE === layer.value && element.id.includes( parentCode ) ) || element.properties.LEVL_CODE === 0
            );
            topoLayer.value.clearLayers();
            topoLayer.value.addData( data );

            zoomLayer.value.clearLayers();
            const dataZoom = JSON.parse( JSON.stringify( data ) );
            dataZoom.objects[Object.keys( data.objects )[0]].geometries = data.objects[Object.keys( data.objects )[0]].geometries.filter( ( element ) =>
                    ( element.properties.LEVL_CODE === layer.value && element.id.includes( parentCode ) )
            );
            zoomLayer.value.addData( dataZoom );
            map.value.fitBounds( zoomLayer.value.getBounds() );
        }

        function zoomToFeature( e ) {
            currentMapPart.value = e.target.feature;
            if( e.target.feature.properties.LEVL_CODE >= 3 ) {
                filterTopoLayer( e.target.feature.id );
                return;
            }
            layer.value = e.target.feature.properties.LEVL_CODE + 1;
            for( const marker of markers.value ) {
                map.value.removeLayer( marker );
            }
            filterTopoLayer( e.target.feature.id );
        }

        function inside( point, vs ) {
            // ray-casting algorithm based on
            // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

            const x = point[0], y = point[1];

            let inside = false;
            for( let i = 0, j = vs.length - 1; i < vs.length; j = i++ ) {
                const xi = vs[i].lat, yi = vs[i].lng;
                const xj = vs[j].lat, yj = vs[j].lng;

                const intersect = ( ( yi > y ) != ( yj > y ) )
                        && ( x < ( xj - xi ) * ( y - yi ) / ( yj - yi ) + xi );
                if( intersect ) inside = !inside;
            }

            return inside;
        }

        function flattenPolygonsRec( polygons: any[] ) {
            if( polygons.length == 0 )
                return [];
            if( !Array.isArray( polygons[0] ) )
                return polygons;
            let result = [];
            for( const polygon of polygons ) {
                const flat = flattenPolygonsRec( polygon );
                if( flat.length == 0 )
                    continue;
                if( !Array.isArray( flat[0] ) )
                    result.push( flat );
                else
                    result = result.concat( flat );
            }
            return result;
        }

        function onEachFeature( feature, layer ) {
            let count = 0;
            for( const event of mockEvents ) {
                for( const polygon of flattenPolygonsRec( layer._latlngs ) ) {
                    if( inside( [ event.lat, event.long ], polygon ) ) {
                        count++;
                        if( feature.properties.LEVL_CODE == 3 ) {
                            const marker = newMarker( event );
                            markers.value.push( marker );
                            marker.addTo( map.value );
                        }
                        break;
                    }
                }
            }
            feature.events = count;
            layer.setStyle( mapStyle( feature ) );
            layer.on( {
                click: zoomToFeature
            } );
        }

        function reset() {
            layer.value = 0;
            filterTopoLayer( "AT" );
            currentMapPart.value = null;
        }

        function newMarker( event ) {
            const marker = new L.Marker( [ event.lat, event.long ], {
                icon: new L.DivIcon( {
                    html: '<div><i class="bi bi-geo-alt-fill h4 translate-middle-x position-absolute bottom-0 text-white"></i></div>',
                    popupAnchor: [ -5, -30 ]
                } )
            } );
            marker.bindPopup( event.event );
            marker.on( 'mouseover', function( e ) {
                this.openPopup();
            } );
            marker.on( 'mouseout', function( e ) {
                this.closePopup();
            } );
            return marker;
        }

        return {
            currentMapPart,
            reset
        };
    }
} );
