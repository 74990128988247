import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column h-100" }
const _hoisted_2 = { class: "text-light ps-5 mt-3 flex-grow-0 flex-shrink-1" }
const _hoisted_3 = {
  class: "px-4 flex-grow-1 overflow-auto",
  id: "infiniteScroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCard = _resolveComponent("EventCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t( 'category.' + _ctx.getCategorySlug( _ctx.eventGroup.category ) )), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventGroup.events, (event) => {
        return (_openBlock(), _createBlock(_component_EventCard, {
          key: event.id,
          event: event,
          class: "mx-1 cursor-pointer d-inline-block",
          onClick: ($event: any) => (_ctx.selectEvent(event)),
          "data-bs-toggle": "modal",
          "data-bs-target": "#homeEventDetailModal"
        }, null, 8, ["event", "onClick"]))
      }), 128))
    ])
  ]))
}