

import { defineComponent, onMounted, PropType, Ref, ref } from "vue";

export default defineComponent( {
    name: 'FullSizeGalleryModal',
    components: {},
    props: {
        id: {
            type: String
        },
        parentModal: {
            type: String,
            default: () => ""
        },
        imgs: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        img: {
            type: String
        },
        autoSwitch: {
            type: Number,
            default: () => 0
        },
        manual: {
            type: Boolean,
            default: () => true
        }
    },
    setup( props ) {
        const currImg: Ref<number> = ref( 0 );

        onMounted( () => {
            if( props.img ) {
                currImg.value = props.imgs.indexOf( props.img );
            }
            const maxImgs = props.imgs.length;
            if( props.autoSwitch > 0 && maxImgs > 1 ) {
                setInterval( () => {
                    nextImg();
                }, props.autoSwitch * 1000 );
            }
        } );

        function nextImg() {
            currImg.value = ( currImg.value + 1 ) % props.imgs.length;
        }

        function lastImg() {
            currImg.value = ( currImg.value - 1 + props.imgs.length ) % props.imgs.length;
        }

        function setImg( i: number ) {
            currImg.value = i;
        }

        return {
            currImg,
            setImg,
            nextImg,
            lastImg
        };
    }
} );
