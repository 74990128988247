

import { defineComponent, getCurrentInstance, onMounted, ref, Ref, watch } from "vue";
import { ApiService } from "@/services/ApiService";
import {
    ArtistDto,
    CategoryApi, CategoryDto,
    EventApi,
    EventDto, EventSearchDto,
    GeoDto,
    LocationDto,
    QuickSearchResponseDto,
    SearchApi, VirtualCategoryDto
} from "@/api";
import { getLocationName, getPerformerTitle, getProfessionsTitle } from "@/utils/EventUtils";
import { useI18n } from "vue-i18n";
import EventDetailModal from "@/components/event/EventDetailModal.vue";
import { useStore } from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import moment from "moment";
import { setQueryStringParameter } from "@/utils/Utils";

const eventApi = ApiService.wrap( EventApi );

export default defineComponent( {
    name: 'HeaderSearch',
    components: { EventDetailModal, Datepicker },
    props: {},
    emits: [ "update", "inFocus", "inBlur" ],
    setup( props, { emit } ) {
        const query: Ref<string> = ref( "" );
        const show: Ref<boolean> = ref( false );
        const showCategories: Ref<boolean> = ref( false );
        const searchApi = ApiService.wrap( SearchApi );
        const categoryApi = ApiService.wrap( CategoryApi );
        const quickresult: Ref<QuickSearchResponseDto> = ref( null );
        const currEvent: Ref<EventDto> = ref( null );
        const container = ref( null );
        const searchDatePicker = ref( null );
        const i18n = useI18n();
        const store = useStore();
        const date: Ref<Date> = ref( new Date );
        const categories: Ref<CategoryDto[]> = ref( [] );
        let lastQuicksearchId;

        onMounted( () => {
            categoryApi.getCategories().then( ( r ) => {
                categories.value = r.data;
            } ).catch( ApiService.defaultErrorHandler );
        } );

        watch( () => query.value, () => {
            if( lastQuicksearchId ) {
                clearTimeout( lastQuicksearchId );
            }
            lastQuicksearchId = setTimeout( () => {
                searchApi.quickSearch( query.value ).then( r => {
                    quickresult.value = r.data;
                } );
            }, 600 );
        } );

        watch( () => date.value, () => {
            store.commit( 'setSearchDate', date.value );
        } );

        function hide( event ) {
            show.value = container.value.contains( event.explicitOriginalTarget );
        }

        function locationName( event: EventDto ) {
            return getLocationName( [], event, i18n, 30 );
        }

        function artistName( event: EventDto ) {
            return getPerformerTitle( [], event, i18n, 30 );
        }

        function professionName( professions: string[] ) {
            return getProfessionsTitle( professions, i18n, 30 );
        }

        function selectEvent( event: EventDto ) {
            currEvent.value = event;
            show.value = false;
        }

        function toggleShowCategories() {
            setTimeout( () => {
                showCategories.value = !showCategories.value;
                if( showCategories.value ) {
                    document.getElementById( 'headerCategoriesDropdown' ).focus();
                }
            }, 100 );
        }

        function search( json: EventSearchDto ) {
            setQueryStringParameter( "q", JSON.stringify( json ) );
            eventApi.getEvents( json ).then( r => {
                store.commit( 'setEventSearchResult', r.data );
            } ).catch( ApiService.defaultErrorHandler );
            show.value = false;
        }

        function searchForArtist( artist: ArtistDto ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                performers: [ artist.id ],
                since: date.value.toISOString()
            };
            search( json );
        }

        function searchForLocation( loc: LocationDto ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                locations: [ loc.id ],
                since: date.value.toISOString()
            };
            search( json );
        }

        function searchForGeoLocation( loc: GeoDto, distance: number ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                geoPosition: loc,
                radius: distance,
                since: date.value.toISOString()
            };
            search( json );
        }

        function searchForCategory( category: string ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                categories: [ category ],
                since: date.value.toISOString()
            };
            search( json );
        }

        function searchForVirtualCategory( category: VirtualCategoryDto ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                virtualCategories: [ category ],
                since: date.value.toISOString()
            };
            search( json );
        }

        function searchByWord() {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                keyword: query.value,
                since: date.value.toISOString()
            };
            search( json );
        }

        function getVirtualCategories(): VirtualCategoryDto[] {
            //TODO: Specific on user
            return [ VirtualCategoryDto.Upcoming, VirtualCategoryDto.Sponsored ];
        }

        return {
            searchDatePicker,
            moment,
            showCategories,
            query,
            show,
            quickresult,
            container,
            currEvent,
            date,
            categories,
            locationName,
            artistName,
            hide,
            professionName,
            toggleShowCategories,
            selectEvent,
            searchForArtist,
            searchForLocation,
            searchForGeoLocation,
            searchForCategory,
            searchForVirtualCategory,
            searchByWord,
            getVirtualCategories,
        };
    }
} );
