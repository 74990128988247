import { createToast, Position, ToastType, TransitionType } from "mosha-vue-toastify";

export abstract class NotificationsService {
    private static readonly position: Position = 'bottom-right';
    private static readonly showCloseButton: boolean = true;
    private static readonly showIcon: boolean = true;
    private static readonly transition: TransitionType = "bounce";

    private static show( type: ToastType, title: string, msg: string ) {
        createToast( { title: title, description: msg }, {
            type: type,
            position: this.position,
            showCloseButton: this.showCloseButton,
            showIcon: this.showIcon,
            transition: this.transition,
        } );
    }

    public static success( title: string, msg: string ) {
        this.show( "success", title, msg );
    }

    public static warning( title: string, msg: string ) {
        this.show( "warning", title, msg );
    }

    public static error( title: string, msg: string ) {
        this.show( "danger", title, msg );
    }

    public static info( title: string, msg: string ) {
        this.show( "info", title, msg );
    }
}