

import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";
import { EventDto, PeriodDto } from "@/api";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { getNextDates, PeriodStartToDate } from "@/utils/PeriodUtils";
import { getLocationName } from "@/utils/EventUtils";

export default defineComponent( {
    name: 'List',
    components: {},
    props: {
        event: {
            type: Object as PropType<EventDto>,
            default: () => null
        }
    },
    setup( props ) {
        const upcomingEvents: Ref<PeriodDto[]> = ref( [] );
        const pageSize = 4;
        const everythingLoaded: Ref<boolean> = ref( true );

        onMounted( () => {
            initEvents();
        } );

        watch( () => props.event, function() {
            initEvents();
        } );

        function initEvents() {
            upcomingEvents.value = [];
            loadEvents();
        }

        function loadMoreEvents() {
            let since = moment( PeriodStartToDate( upcomingEvents.value[upcomingEvents.value.length - 1] ) ).add( 1, 'minute' );
            loadEvents( since.toDate() );
        }

        function loadEvents( since: Date = new Date() ) {
            if( props.event ) {
                everythingLoaded.value = true;
                const upcoming = getNextDates( props.event.periods, pageSize, since );
                upcomingEvents.value = upcomingEvents.value.concat( upcoming );
                everythingLoaded.value = upcoming.length != pageSize;
            } else {
                upcomingEvents.value = [];
            }
        }

        return {
            moment,
            getLocationName,
            loadMoreEvents,
            upcomingEvents,
            everythingLoaded
        };
    }
} );
