export default {
    common: {
        ok: "OK",
        login: "Login",
        events: "Events",
        artists: "Personen/Gruppen",
        contact: "Kontakt",
        locations: "Veranstaltungsorte",
        cities: "Orte",
        andXMore: " & {x} mehr",
        gallery: "Gallerie",
        dates: "Termine",
        loadMore: "Mehr laden",
    },
    notification: {
        error: "Fehler"
    },
    search: {
        showAll: "Alle {count} anzeigen",
    },
    img: {
        logo: "Logo der Website",
    },
    event: {
        description: "Beschreibung",
        price: "Preis",
        date: "Datum",
        performer: "Darsteller",
        location: "Ort",
        genre: "Genre",
        images: "Bilder",
    },
    init: {
        geoSearch: "Wo suchen Sie Events",
        radius: "Radius",
        around: "um"
    },
    map: {
        source: "Daten © EuroGeographics bezüglich der Verwaltungsgrenzen"
    },
    category: { //Virtual uppercase else lowercase
        Upcoming: "Bevorstehend",
        Sponsored: "Gesponsort",
        InterestedPerformer: "Interpreten die du magst",
        Subscribed: "Abonniert",
        None: "Sonstiges",
        showAll: "Alle {count} anzeigen",
        categories: "Genres",
        sport: "Sport",
        acrobatics: "Akrobatik",
        mountaineering: "Bergsport",
        climbing: "Klettern",
        bouldering: "Bouldern",
        skiing: "Skifahren",
        snowboarding: "Snowboarden",
        hiking: "Wandern",
        touring: "Tourengehen",
        others: "Sonstige",
        ballSports: "Ballsport",
        football: "Fußball",
        volleyball: "Volleyball",
        handball: "Handball",
        basketball: "Basketball",
        americanFootball: "Football",
        tennis: "Tennis",
        tableTennis: "Tischtennis",
        waterSports: "Wassersport",
        canoeing: "Kanu",
        sailing: "Segeln",
        rowing: "Rudern",
        swimming: "Schwimmen",
        diving: "Tauchen",
        surfing: "Surfen",
        enduranceSports: "Ausdauersport",
        running: "Laufen",
        triathlon: "Triathlon",
        crossCountrySkiing: "Langlaufen",
        biathlon: "Biathlon",
        biking: "Rad",
        mindSport: "Denksport",
        chess: "Schach",
        eSport: "E-Sport",
        cardgames: "Kartenspiele",
        music: "Musik",
        pop: "Pop",
        electronicMusic: "Elektronische Musik",
        hipHop: "Hip-Hop",
        rock: "Rock",
        metal: "Metal",
        jazz: "Jazz",
        gospelAndSpiritual: "Gospel & Spiritual",
        folkMusic: "Volksmusik",
        classic: "Klassik",
        epic: "Epic",
        opera: "Oper",
        musical: "Musical",
        entertainment: "Unterhaltung",
        cabaret: "Kabarett",
        festivals: "Feste",
        theatre: "Theater",
        dance: "Tanz",
        art: "Kunst",
        poetrySlam: "Poetry Slam",
        exhibition: "Ausstellung",
        vernissage: "Vernissage",
        reading: "Lesung",
        advancedTraining: "Fortbildungen",
    },
    moment: {
        format: "dd DD.MM.YY HH:mm",
        formatDate: "dd DD.MM.YY",
        formatDay: "DD.MM",
        formatTime: "HH:mm",
        locale: "de-at",
    },
    time: {
        list: "Liste",
        calendar: "Kalender",
        notFound: "Keine anstehenden Termine",
        startsAt: "Ab {date}",
        nextDate: "Nächster Termin: {date}",
        everyDayWeekdaySpan: "Jeden {min} - {max}",
        everyXofY: "Jeden {x} {ofY}",
        everyDay: "Täglich",
        day: "Tag",
        everyX: {
            x1: "1.",
            x2: "2.",
            x3: "3.",
            xm1: "letzten",
            xm2: "vorletzten",
        },
        ofY: {
            week: "der {x}Woche",
            month: "des {x}Monats",
            year: "des Jahres",
        },
        days: {
            d1: "{x}Mo",
            d2: "{x}Di",
            d3: "{x}Mi",
            d4: "{x}Do",
            d5: "{x}Fr",
            d6: "{x}Sa",
            d7: "{x}So",
        }
    },
    date: {
        month: {
            jan: "Jänner",
            feb: "Februar",
            mar: "März",
            apr: "April",
            may: "Mai",
            jun: "Juni",
            jul: "Juli",
            aug: "August",
            sep: "September",
            oct: "Oktober",
            nov: "November",
            dec: "Dezember",
            m0: "Jänner",
            m1: "Februar",
            m2: "März",
            m3: "April",
            m4: "Mai",
            m5: "Juni",
            m6: "Juli",
            m7: "August",
            m8: "September",
            m9: "Oktober",
            m10: "November",
            m11: "Dezember",
        },
        days: {
            mo: "Montag",
            tu: "Dienstag",
            we: "Mittwoch",
            th: "Donnerstag",
            fr: "Freitag",
            sa: "Samstag",
            su: "Sonntag",
        },
    },
}