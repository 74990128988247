

import { defineComponent, onMounted, PropType, Ref, ref } from "vue";
import {
    EventApi,
    EventDto,
    EventGroupDto,
    EventSearchDto,
    EventSearchResponseDto,
    PageDto,
    VirtualCategoryDto
} from "@/api";
import { getCategorySlug } from "@/utils/EventUtils";
import EventCard from "@/components/event/EventCard.vue";
import { setQueryStringParameter } from "@/utils/Utils";
import { ApiService } from "@/services/ApiService";
import { useStore } from "vuex";

interface Props {
    eventGroup: EventGroupDto;
}

export default defineComponent( {
    name: 'EventsFullPage',
    components: { EventCard },
    props: {
        eventGroup: {
            type: Object as PropType<EventGroupDto>
        },
    },
    emits: [ "selectEvent" ],
    setup( props: Props, { emit } ) {
        const store = useStore();
        const eventApi = ApiService.wrap( EventApi );
        const currPage: Ref<number> = ref( props.eventGroup.page.page );

        function selectEvent( event: EventDto ) {
            emit( "selectEvent", event );
        }

        onMounted( () => {
            const element = document.getElementById( "infiniteScroll" ) as any;
            element.addEventListener( 'scroll', function( event ) {
                const element = event.target;
                if( element.scrollHeight - element.scrollTop === element.clientHeight ) {
                    searchNextPage();
                }
            } );
        } );

        function search( json: EventSearchDto ) {
            setQueryStringParameter( "q", JSON.stringify( json ) );
            eventApi.getEvents( json ).then( r => {
                if( r.data.eventGroups.length == 1 )
                    props.eventGroup.events = props.eventGroup.events.concat( r.data.eventGroups[0].events );
            } ).catch( ApiService.defaultErrorHandler );
        }

        function searchForCategory( category: string, page: PageDto ) {
            const json = {
                page: page,
                since: store.state.searchDate,
                categories: [ category ]
            };
            search( json );
        }

        function searchForVirtualCategory( category: VirtualCategoryDto, page: PageDto ) {
            const json = {
                page: page,
                since: store.state.searchDate,
                virtualCategories: [ category ]
            };
            search( json );
        }

        function searchNextPage() {
            const category = props.eventGroup.category as any;
            if( ( currPage.value + 1 ) * props.eventGroup.page.size > props.eventGroup.page.totalResults )
                return;
            currPage.value += 1;
            const page = {
                page: currPage.value,
                size: props.eventGroup.page.size
            };
            if( category.hasOwnProperty( 'id' ) ) {
                searchForCategory( category.id, page );
            }
            searchForVirtualCategory( category, page );
        }

        return {
            getCategorySlug,
            selectEvent
        };
    }
} );
