import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "table table-dark table-borderless d-flex" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t( 'common.contact' )), 1),
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact) => {
          return (_openBlock(), _createElementBlock("tr", { key: contact }, [
            _createElementVNode("td", null, _toDisplayString(contact.title), 1),
            _createElementVNode("td", null, [
              (contact.website)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    target: "_blank",
                    href: '//' + contact.website
                  }, _toDisplayString(contact.website), 9, _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", null, [
              (contact.email)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: 'mailto:' + contact.email
                  }, _toDisplayString(contact.email), 9, _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", null, [
              (contact.phone)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: 'tel:' + contact.phone
                  }, _toDisplayString(contact.phone), 9, _hoisted_5))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}