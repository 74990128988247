import { PeriodDto } from "@/api";
import moment, { Moment } from "moment";

export function periodsToStringAndNextDate( periods: PeriodDto[], i18n: any, since: Date = new Date(), locationId: string = null ): [ string, PeriodDto ] {
    const nextDates = getNextDates( periods, 31, since, locationId );
    const nextDateIds = nextDates.map( ( p ) => p.id );
    const text = periodsToString( periods.filter( ( p ) => !locationId || p.locationIds.includes( locationId ) ), periods.filter( ( p ) => nextDateIds.includes( p.id ) ), nextDates, i18n, since );
    const nextDate = getNextDates( periods, 1, since, locationId, [ "Normal", "Special", "Canceled", "Moved" ], [ "Closed" ] );
    return [ text, nextDate?.length === 1 ? nextDate[0] : null ];
}

export function periodsToString( periods: PeriodDto[], upcomingPeriods: PeriodDto[], nextEvents: PeriodDto[], i18n: any, since: Date ): string {
    const debug = false;
    moment.locale( i18n.t( 'moment.locale' ) );
    if( upcomingPeriods.length == 0 ) {
        if( debug ) console.log( "periodsToString", "nothing found" );
        return i18n.t( 'time.notFound' );
    }
    if( upcomingPeriods.length == 1 && periodHasNoRepeats( upcomingPeriods[0] ) ) {
        const nextDate = nextEvents[0];
        const startDate = PeriodStartToDate( nextDate );
        const endDate = PeriodEndToDate( nextDate );
        if( debug ) console.log( "periodsToString", "justOneDate" );
        if( startDate.toDateString() == endDate.toDateString() )
            return moment( startDate ).format( i18n.t( 'moment.format' ) );
        return moment( startDate ).format( i18n.t( 'moment.format' ) ) + ' - ' + moment( endDate ).format( i18n.t( 'moment.format' ) );
    }
    if( nextEvents[0].type === "Special" || periodHasNoRepeats( upcomingPeriods.find( ( p ) => p.id == nextEvents[0].id ) ) ) {
        if( debug ) console.log( "periodsToString", "special", upcomingPeriods );
        return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextEvents[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }

    let repeats = "";
    const normalPeriods = upcomingPeriods.filter( ( p ) => p.type === "Normal" );
    for( const period of normalPeriods ) {
        if( repeats == "" )
            repeats = repeatsToString( period );
        else if( repeats != repeatsToString( period ) ) {
            if( debug ) console.log( "periodsToString", "noMatchingRepeats" );
            return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextEvents[0] ) ).format( i18n.t( 'moment.format' ) ) } );
        }
    }
    switch( repeats ) {
        case "0001":
            if( debug ) console.log( "periodsToString", "0001" );
            return dailyPeriodsToString( nextEvents, normalPeriods, periods, since, i18n );
        case "0011":
            if( debug ) console.log( "periodsToString", "0011" );
            return weeklyPeriodsToString( nextEvents, normalPeriods, periods, since, i18n );
        case "0101":
            if( debug ) console.log( "periodsToString", "0101" );
            return monthlyDayPeriodsToString( nextEvents, normalPeriods, periods, since, i18n );
        case "0111":
            if( debug ) console.log( "periodsToString", "0111" );
            return monthlyWeekdayPeriodsToString( nextEvents, normalPeriods, periods, since, i18n );
        default:
            if( debug ) console.log( "periodsToString", "default" );
            return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextEvents[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }
}

function continuousWeekdays( days: Array<number> ): boolean {
    if( days.length == 1 ) {
        return true;
    }
    return days.every( ( d ) => days.includes( ( d + 1 ) % 7 ) || days.includes( ( d - 1 ) % 7 ) || days.includes( d + 1 ) || days.includes( d - 1 ) );
}

function dailyPeriodsToString( nextDates: PeriodDto[], normalPeriods: PeriodDto[], periods: PeriodDto[], since: Date, i18n: any ) {
    let closed = periods.filter( ( p ) => p.type === "Closed" && ( !p.endDate || PeriodEndToDate( p ) > since ) );
    const invalidClosedPeriods = [ "0001", "0101", "0111" ];
    if( closed.some( ( p ) => invalidClosedPeriods.includes( repeatsToString( p ) ) ) ) {
        return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }
    if( normalPeriods.every( ( p ) => p.repeatDay == 1 ) ) {
        closed = closed.filter( ( p ) => repeatsToString( p ) === "0011" );
        if( closed.length == 0 ) {
            return i18n.t( 'time.everyDay' );
        }
        const closedDays = new Set( closed.map( ( p ) => p.repeatDay ) );
        let dayArray = Array.from( closedDays.values() );
        dayArray.sort();
        if( continuousWeekdays( dayArray ) ) {
            let minD = dayArray[0];
            let maxD = dayArray[dayArray.length - 1];
            if( minD == 1 && maxD == 7 ) {
                let lastDay = 0;
                for( const d of dayArray ) {
                    if( d - 1 != lastDay ) {
                        maxD = lastDay + 1;
                        minD = d - 1;
                        break;
                    }
                }
            } else {
                maxD = ( maxD + 1 ) % 7;
                minD = ( minD - 1 ) % 7;
            }
            if( minD != maxD )
                return i18n.t( 'time.everyDayWeekdaySpan', {
                    min: i18n.t( 'time.days.d' + maxD.toString() ),
                    max: i18n.t( 'time.days.d' + minD.toString() )
                } );
            else
                return i18n.t( 'time.everyXofY', { x: i18n.t( 'time.days.d' + maxD.toString() ) } );
        } else {
            const openDays = Array.from( { length: 7 }, ( _, i ) => i + 1 ).filter( ( d ) => !dayArray.includes( d ) );
            let openDayString = openDays.map( ( d ) => i18n.t( 'time.days.d' + d.toString() ) ).join( ',' );
            let pos = openDayString.lastIndexOf( ',' );
            if( pos > 0 )
                openDayString = openDayString.substring( 0, pos ) + ' & ' + openDayString.substring( pos + 1 );
            return i18n.t( 'time.everyXofY', { x: openDayString } );
        }
    }

    return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
}


function weeklyPeriodsToString( nextDates: PeriodDto[], normalPeriods: PeriodDto[], periods: PeriodDto[], since: Date, i18n: any ) {
    let closed = periods.filter( ( p ) => p.type === "Closed" && ( !p.endDate || PeriodEndToDate( p ) > since ) );
    const invalidClosedPeriods = [ "0001", "0101", "0111", "0011" ];
    if( closed.some( ( p ) => invalidClosedPeriods.includes( repeatsToString( p ) ) ) ) {
        return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }
    const openDays = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatDay ) ) );
    openDays.sort();
    const openWeeks = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatWeek ) ) );
    if( openWeeks.length == 1 ) {
        if( openWeeks[0] == 1 ) {
            let openDayString = openDays.map( ( d ) => i18n.t( 'time.days.d' + d.toString() ) ).join( ',' );
            let pos = openDayString.lastIndexOf( ',' );
            if( pos > 0 )
                openDayString = openDayString.substring( 0, pos ) + ' & ' + openDayString.substring( pos + 1 );
            return i18n.t( 'time.everyXofY', { x: openDayString } );
        }
        if( openDays.length == 1 && ( openWeeks[0] >= -2 || openWeeks[0] < 4 ) ) {
            return i18n.t( 'time.everyXofY', {
                x: i18n.t( 'time.everyX.x' + openWeeks[0].toString() ),
                ofY: i18n.t( 'time.days.d' + openDays[0].toString() )
            } );
        }
    }
    return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
}

function monthlyDayPeriodsToString( nextDates: PeriodDto[], normalPeriods: PeriodDto[], periods: PeriodDto[], since: Date, i18n: any ) {
    let closed = periods.filter( ( p ) => p.type === "Closed" && ( !p.endDate || PeriodEndToDate( p ) > since ) );
    const invalidClosedPeriods = [ "0001", "0101", "0111", "0011" ];
    if( closed.some( ( p ) => invalidClosedPeriods.includes( repeatsToString( p ) ) ) ) {
        return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }
    const openDays = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatDay ) ) );
    const openMonths = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatMonth ) ) );
    if( openMonths.length == 1 ) {
        let openDayString = openDays.map( ( d ) => +d.toString() + '.' ).join( ',' );
        let pos = openDayString.lastIndexOf( ',' );
        if( pos > 0 )
            openDayString = openDayString.substring( 0, pos ) + ' & ' + openDayString.substring( pos + 1 );
        if( openDays.length == 1 && openDays[0] < 0 && openDays[0] >= -2 ) {
            openDayString = i18n.t( 'time.everyX.' + ( openDays[0] < 0 ? 'xm' : 'x' ) + Math.abs( openDays[0] ).toString() ) + ' ' + i18n.t( 'time.day' );
        }
        if( openMonths[0] == 1 ) {
            return i18n.t( 'time.everyXofY', {
                x: openDayString,
                ofY: i18n.t( 'time.ofY.month' )
            } );
        }
        if( openDays.length == 1 && ( openMonths[0] < 4 ) ) {
            return i18n.t( 'time.everyXofY', {
                x: openDayString,
                ofY: i18n.t( 'time.ofY.month', { x: i18n.t( 'time.everyX.x' + openMonths[0].toString() ) + " " } )
            } );
        }
    }

    return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
}

function monthlyWeekdayPeriodsToString( nextDates: PeriodDto[], normalPeriods: PeriodDto[], periods: PeriodDto[], since: Date, i18n: any ) {
    let closed = periods.filter( ( p ) => p.type === "Closed" && ( !p.endDate || PeriodEndToDate( p ) > since ) );
    const invalidClosedPeriods = [ "0001", "0101", "0111", "0011" ];
    if( closed.some( ( p ) => invalidClosedPeriods.includes( repeatsToString( p ) ) ) ) {
        return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
    }
    const openDays = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatDay ) ) );
    const openWeeks = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatWeek ) ) );
    const openMonths = Array.from( new Set( normalPeriods.map( ( p ) => p.repeatMonth ) ) );
    if( openMonths.length == 1 && openWeeks.length == 1 && openDays.length == 1 ) {
        if( openMonths[0] == 1 ) {
            return i18n.t( 'time.everyXofY', {
                x: i18n.t( 'time.days.d' + openDays[0].toString(),
                        { x: i18n.t( 'time.everyX.' + ( openWeeks[0] < 0 ? 'xm' : 'x' ) + Math.abs( openWeeks[0] ).toString() ) + " " } ),
                ofY: i18n.t( 'time.ofY.month' )
            } );
        }
        if( ( openMonths[0] < 4 ) ) {
            return i18n.t( 'time.everyXofY', {
                x: i18n.t( 'time.days.d' + openDays[0].toString(),
                        { x: i18n.t( 'time.everyX.' + ( openWeeks[0] < 0 ? 'xm' : 'x' ) + Math.abs( openWeeks[0] ).toString() ) + " " } ),
                ofY: i18n.t( 'time.ofY.month',
                        { x: i18n.t( 'time.everyX.' + ( openMonths[0] < 0 ? 'xm' : 'x' ) + Math.abs( openMonths[0] ).toString() ) + " " } )
            } );
        }
    }
    return i18n.t( 'time.nextDate', { date: moment( PeriodStartToDate( nextDates[0] ) ).format( i18n.t( 'moment.format' ) ) } );
}

function repeatsToString( period: PeriodDto ): string {
    let result = "";
    result += period.repeatYear == 0 ? "0" : "1";
    result += period.repeatMonth == 0 ? "0" : "1";
    result += period.repeatWeek == 0 ? "0" : "1";
    result += period.repeatDay == 0 ? "0" : "1";
    return result;
}

function dateRangeOverlaps( a_start: Date, a_end: Date, b_start: Date, b_end: Date ) {
    if( a_start <= b_start && b_start <= a_end ) return true; // b starts in a
    if( b_start < a_start && ( !b_end || a_end < b_end ) ) return true; // a in b
    if( b_end && ( a_start <= b_end && b_end <= a_end ) ) return true; // b ends in a
    return false;
}

export function periodHasNoRepeats( period: PeriodDto ): boolean {
    return period.repeatDay == 0 && period.repeatWeek == 0 && period.repeatMonth == 0 && period.repeatYear == 0;
}

//period has to be a no-repeat period
function isValid( period: PeriodDto, exception: PeriodDto[] ) {
    const repeatingClosed = [];
    if( period.type == "Normal" ) {
        for( const closed of exception.filter( ( p ) => p.type == "Closed" ) ) {
            if( periodHasNoRepeats( closed ) && dateRangeOverlaps( PeriodStartToDate( period ), PeriodEndToDate( period ), PeriodStartToDate( closed ), PeriodEndToDate( closed ) ) ) {
                return false;
            } else if( !periodHasNoRepeats( closed ) ) {
                repeatingClosed.push( closed );
            }
        }
    }
    for( const closed of getNextDates( repeatingClosed, 10, moment( PeriodStartToDate( period ) ).add( -1, "d" ).toDate(), null, [ "Closed" ], [] ) ) {
        if( dateRangeOverlaps( PeriodStartToDate( period ), PeriodEndToDate( period ), PeriodStartToDate( closed ), PeriodEndToDate( closed ) ) ) {
            return false;
        }
    }
    for( const p of exception.filter( ( p ) => period.referenceIds.includes( p.id ) ) ) {
        if( dateRangeOverlaps( PeriodStartToDate( period ), PeriodEndToDate( period ), PeriodStartToDate( p ), PeriodEndToDate( p ) ) ) {
            return false;
        }
    }
    return true;
}

export function getNextDates( periods: PeriodDto[], size: number, since: Date = new Date(), locationId: string = null,
                              inclTypes: string[] = [ "Normal", "Special" ], exclTypes: string[] = [ "Closed", "Canceled", "Moved" ], remainingRepeats: number = 300 ): PeriodDto[] {
    if( remainingRepeats == 0 || periods.length == 0 ) {
        return [];
    }
    let upcoming = periods.filter( ( i ) => ( inclTypes.includes( i.type ) )
            && ( !i.lastEnd || new Date( i.lastEnd ) >= since ) );
    let exception = periods.filter( ( i ) => ( exclTypes.includes( i.type ) )
            && ( !i.lastEnd || new Date( i.lastEnd ) >= since ) );
    if( locationId ) {
        upcoming = upcoming.filter( ( p ) => p.locationIds.includes( locationId ) );
        exception = exception.filter( ( p ) => p.locationIds.includes( locationId ) );
    }
    let nextUpcoming = getNextUpcoming( upcoming, since ); //get min and check
    if( nextUpcoming == null ) {
        return [];
    }
    let upcomingRec = [];
    if( isValid( nextUpcoming, exception ) ) {
        if( size == 1 ) {
            return [ nextUpcoming ];
        }
        upcomingRec = getNextDates( periods, size - 1, moment( PeriodStartToDate( nextUpcoming ) ).add( 1, "minute" ).toDate(), locationId, inclTypes, exclTypes, remainingRepeats - 1 );
        upcomingRec = [ nextUpcoming ].concat( upcomingRec );
    } else {
        upcomingRec = getNextDates( periods, size, moment( PeriodStartToDate( nextUpcoming ) ).add( 1, "minute" ).toDate(), locationId, inclTypes, exclTypes, remainingRepeats - 1 );
    }

    return upcomingRec;
}

export function PeriodStartToDate( periodDto: PeriodDto ): Date {
    const debug = false;
    if( debug ) console.log( "PeriodStartToDate", periodDto.startDate + 'T' + ( periodDto.startTime ? periodDto.startTime : "00:00" ) );
    return new Date( periodDto.startDate + 'T' + ( periodDto.startTime ? periodDto.startTime : "00:00" ) );
}

export function PeriodEndToDate( periodDto: PeriodDto ): Date {
    if( !periodDto.endDate ) {
        if( periodHasNoRepeats( periodDto ) ) {
            let end;
            if( parseFloat( periodDto.startTime.replace( ':', '.' ) ) < parseFloat( periodDto.endTime.replace( ':', '.' ) ) ) {
                end = periodDto.startDate;
            } else {
                end = moment( periodDto.startDate, "YYYY-MM-DD" ).add( 1, 'day' ).format( "YYYY-MM-DD" );
            }
            return new Date( end + 'T' + ( periodDto.endTime ? periodDto.endTime : "23:59" ) );
        } else {
            return null;
        }
    }
    return new Date( periodDto.endDate + 'T' + ( periodDto.endTime ? periodDto.endTime : "23:59" ) );
}

function getMinPeriod( nextPerPeriod: PeriodDto[] ) {
    let result = null;
    let currMinDate = null;
    for( const periodDto of nextPerPeriod ) {
        if( !periodDto )
            continue;
        const currDate = PeriodStartToDate( periodDto );
        if( currMinDate == null ) {
            result = periodDto;
            currMinDate = currDate;
            continue;
        }
        if( currMinDate > currDate ) {
            result = periodDto;
            currMinDate = currDate;
        } else if( currMinDate == currDate && periodDto.type == "Special" ) {
            result = periodDto;
            currMinDate = currDate;
        }
    }
    return result;
}

function nextOfWeekday( date: Moment, weekday: number ): Moment {
    const currWeekday = moment().isoWeekday();
    if( currWeekday <= weekday ) {
        return date.isoWeekday( weekday );
    } else {
        return date.add( 1, 'weeks' ).isoWeekday( weekday );
    }
}

function getNextUpcoming( upcoming: PeriodDto[], since: Date ): PeriodDto {
    const debug = false;
    const nextPerPeriod = [];
    const sinceMoment = moment( since );
    if( debug ) console.log( "------------------------------------------------------------------------------------" );
    if( debug ) console.log( "getNextUpcoming", upcoming, since );
    for( const period of upcoming ) {
        let startDate = moment( PeriodStartToDate( period ) );
        if( debug ) console.log( "getNextUpcoming", PeriodStartToDate( period ) );
        if( debug ) console.log( "getNextUpcoming", startDate );
        if( debug ) console.log( "getNextUpcoming", sinceMoment );
        const periodStartDate = startDate.clone();
        if( !period.repeatYear || period.repeatYear == 0 ) {
            if( !period.repeatMonth || period.repeatMonth == 0 ) {
                if( !period.repeatWeek || period.repeatWeek == 0 ) {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "0000" );
                        if( PeriodStartToDate( period ) > since )
                            nextPerPeriod.push( ToSinglePeriod( period, period.startDate, period.startTime, period.endDate, period.endTime ) );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "0001" );
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatDay, 'day' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                } else {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "0010" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "0011" );
                        startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatWeek, 'week' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                }

            } else {
                if( !period.repeatWeek || period.repeatWeek == 0 ) {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "0100" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "0101" );
                        const dayOfMonth = period.repeatDay > 0 ? period.repeatDay : period.repeatDay + 1;
                        startDate.date( dayOfMonth );
                        if( startDate < periodStartDate ) {
                            startDate.add( 1, 'month' );
                        }
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatMonth, 'month' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                } else {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "0110" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "0111" );
                        startDate.date( 1 );
                        startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                        let weekOfMonth = period.repeatWeek;
                        if( period.repeatWeek < 0 ) {
                            startDate.add( 1, 'month' );
                        } else {
                            weekOfMonth--; //already in week 1
                        }
                        startDate.add( weekOfMonth, 'week' );
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatMonth, 'month' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                }
            }
        } else {
            if( !period.repeatMonth || period.repeatMonth == 0 ) {
                if( !period.repeatWeek || period.repeatWeek == 0 ) {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "1000" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "1001" );
                        const dayOfYear = period.repeatDay > 0 ? period.repeatDay : period.repeatDay + 1;
                        startDate.dayOfYear( dayOfYear );
                        if( startDate < periodStartDate ) {
                            startDate.add( 1, 'year' );
                        }
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatYear, 'year' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                } else {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "1010" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "1011" );
                        startDate.dayOfYear( 1 );
                        startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                        let weekOfYear = period.repeatWeek;
                        if( period.repeatWeek < 0 ) {
                            startDate.add( 1, 'year' );
                        } else {
                            weekOfYear--; //already in week 1
                        }
                        startDate.add( weekOfYear, 'week' );
                        while( startDate < periodStartDate ) {
                            startDate.add( 1, 'year' );
                        }
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatYear, 'year' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                }

            } else {
                if( !period.repeatWeek || period.repeatWeek == 0 ) {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "1100" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "1101" );
                        const dayOfMonth = period.repeatDay > 0 ? period.repeatDay : period.repeatDay + 1;
                        startDate.date( dayOfMonth );
                        startDate.month( period.repeatMonth - 1 );
                        if( startDate < periodStartDate ) {
                            startDate.add( 1, 'year' );
                        }
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatYear, 'year' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                } else {
                    if( !period.repeatDay || period.repeatDay == 0 ) {
                        if( debug ) console.log( "getNextUpcoming", "1110" );
                        throw new InvalidPeriodError( "", period );
                    } else {
                        if( debug ) console.log( "getNextUpcoming", "1111" );
                        startDate.date( 1 );
                        startDate.month( period.repeatMonth - 1 );
                        startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                        let weekOfMonth = period.repeatWeek;
                        if( period.repeatWeek < 0 ) {
                            startDate.add( 1, 'month' );
                        } else {
                            weekOfMonth--; //already in week 1
                        }
                        startDate.add( weekOfMonth, 'week' );
                        if( startDate < periodStartDate ) {
                            startDate.add( 1, 'year' );
                            startDate.date( 1 );
                            startDate.month( period.repeatMonth - 1 );
                            startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                            let weekOfMonth = period.repeatWeek;
                            if( period.repeatWeek < 0 ) {
                                startDate.add( 1, 'month' );
                            } else {
                                weekOfMonth--; //already in week 1
                            }
                            startDate.add( weekOfMonth, 'week' );
                        }
                        while( startDate < sinceMoment ) {
                            startDate.add( period.repeatYear, 'year' );
                            startDate.date( 1 );
                            startDate.month( period.repeatMonth - 1 );
                            startDate = nextOfWeekday( startDate, period.repeatDay % 7 );
                            let weekOfMonth = period.repeatWeek;
                            if( period.repeatWeek < 0 ) {
                                startDate.add( 1, 'month' );
                            } else {
                                weekOfMonth--; //already in week 1
                            }
                            startDate.add( weekOfMonth, 'week' );
                        }
                        const end = PeriodEndToDate( period );
                        if( !end || end > startDate.toDate() )
                            nextPerPeriod.push( ToSinglePeriod( period, startDate.format( "YYYY-MM-DD" ), period.startTime, null, period.endTime ) );
                    }
                }
            }
        }
    }
    return getMinPeriod( nextPerPeriod );
}


function ToSinglePeriod( period: PeriodDto, startDate: string, startTime: string, endDate: string, endTime: string ): PeriodDto {
    let end = endDate;
    if( !end ) {
        if( !endTime || !startTime ) {
            end = startDate;
        } else {
            if( parseFloat( startTime.replace( ':', '.' ) ) < parseFloat( endTime.replace( ':', '.' ) ) ) {
                end = startDate;
            } else {
                end = moment( startDate, "YYYY-MM-DD" ).add( 1, 'day' ).format( "YYYY-MM-DD" );
            }
        }
    } else {
        if( new Date( end ) > new Date( period.endDate ) ) {
            return null;
        }
    }
    return {
        id: period.id,
        referenceIds: period.referenceIds,
        type: period.type,
        locationIds: period.locationIds,
        startDate: startDate,
        endDate: end,
        startTime: startTime,
        endTime: endTime,
        repeatYear: 0,
        repeatMonth: 0,
        repeatDay: 0,
        repeatWeek: 0
    };
}