import { ConfigService } from "@/services/ConfigService";

export function isObject( obj ) {
    return typeof obj === 'object' && obj !== null;
}

export let lastSearchQuery = "/";

export function setQueryStringParameter( name, value ) {
    const params = new URLSearchParams( window.location.search );
    params.set( name, value );
    lastSearchQuery = decodeURIComponent( `/?${params}` );
    window.history.pushState( {}, "", lastSearchQuery );
}

export function setTitle( subtitle: string ) {
    let title = ConfigService.getTitle();
    if( subtitle )
        title += " - " + subtitle;
    document.title = title;
}