

import { defineComponent, onMounted, PropType, ref, Ref } from "vue";
import { EventApi, EventDto, EventGroupDto, EventSearchDto, VirtualCategoryDto } from "@/api";
import EventCard from "@/components/event/EventCard.vue";
import Gallery from "@/components/general/Gallery.vue";
import { getCategoryId, getCategorySlug } from "@/utils/EventUtils";
import { ApiService } from "@/services/ApiService";
import { useStore } from "vuex";
import { setQueryStringParameter } from "@/utils/Utils";

interface Props {
    eventGroup: EventGroupDto;
}

export default defineComponent( {
    name: 'EventGroup',
    components: { Gallery, EventCard },
    props: {
        eventGroup: {
            type: Object as PropType<EventGroupDto>
        },
        singleLine: {
            type: Boolean
        }
    },
    emits: [ "selectEvent" ],
    setup( props: Props, { emit } ) {
        const eventApi = ApiService.wrap( EventApi );
        const store = useStore();

        function selectEvent( event: EventDto ) {
            emit( "selectEvent", event );
        }

        function search( json: EventSearchDto ) {
            setQueryStringParameter( "q", JSON.stringify( json ) );
            eventApi.getEvents( json ).then( r => {
                store.commit( 'setEventSearchResult', r.data );
            } ).catch( ApiService.defaultErrorHandler );
        }

        function searchForCategory( category: string ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                since: store.state.searchDate,
                categories: [ category ]
            };
            search( json );
        }

        function searchForVirtualCategory( category: VirtualCategoryDto ) {
            const json = {
                page: {
                    page: 0,
                    size: 30
                },
                since: store.state.searchDate,
                virtualCategories: [ category ]
            };
            search( json );
        }

        function searchForAnyCategory( b, category ) {
            if( !b ) {
                return;
            }
            if( category.hasOwnProperty( 'id' ) ) {
                searchForCategory( category.id );
            }
            searchForVirtualCategory( category );
        }

        return {
            selectEvent,
            getCategorySlug,
            getCategoryId,
            searchForAnyCategory
        };
    }
} );
